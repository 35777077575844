import axios from 'axios';

const apiPath = 'https://www.googleapis.com/customsearch/v1';
const apiKey = 'AIzaSyDJhU8WNsugmi8c1gZmUVFoJL7A2r3zjhI';
const id = '007512778548519504818:jwzg-yq8cpm';

export const getUrl = query => `https://cse.google.com/cse?cx=${id}&q=${query}`;

export const lookup = async query => {
  const response = await axios.get(
    `${apiPath}?key=${apiKey}&cx=${id}&q=${query}`
  );
  return response.data.items;
};
