import React from 'react';
import {
  Box,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ExpandMore } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  image: {
    border: ({ bordered }) =>
      bordered && `${theme.palette.grey['500']} solid 1px`,
    maxWidth: '100%',
    width: ({ stretched }) => stretched && '100%'
  },
  imageCaption: {
    margin: `${theme.spacing(1)}px 0px`
  },
  paragraph: {
    margin: '1em 0px'
  }
}));

const FaqViewer = ({ content }) => {
  let faqs = [];
  let faq;

  content.blocks.forEach(block => {
    if (block.type === 'header') {
      faq = { summary: block.data.text };
    } else if (block.type === 'paragraph') {
      faq = { ...faq, description: block.data.text };
      faqs.push(faq);
    }
  });

  return faqs.map(faq => (
    <ExpansionPanel key={faq.summary.slice(0, 20)}>
      <ExpansionPanelSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="h6">{faq.summary}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Typography dangerouslySetInnerHTML={{__html: faq.description}}/>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  ));
};

const BlockViewer = ({ content }) => {
  const { imageCaption, paragraph } = useStyles();
  if (content === '') return null;
  return (
    <>
      {content.blocks.map((block, index) => {
        switch (block.type) {
          case 'paragraph':
            return (
              <Typography
                key={index}
                className={paragraph}
                dangerouslySetInnerHTML={{ __html: block.data.text }}
              />
            );
          case 'header': {
            return (
              <Typography key={index} variant={`h${block.data.level}`}>
                {block.data.text}
              </Typography>
            );
          }
          case 'image': {
            return (
              <Box
                key={index}
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                py={1}
                bgcolor={block.data.withBackground ? 'text.secondary' : null}
              >
                <Image data={block.data} />
                <Typography className={imageCaption} variant="caption">
                  {block.data.caption}
                </Typography>
              </Box>
            );
          }
          case 'list': {
            return (
              <Box key={`item${index}`} pl={2}>
                {block.data.items.map((item, itemIndex) => {
                  return (
                    <Box key={itemIndex} display="flex">
                      <Box mr={1}>
                        <Typography align="center">
                          {block.data.style === 'ordered'
                            ? `${itemIndex + 1}.`
                            : '•'}
                        </Typography>
                      </Box>
                      <Typography dangerouslySetInnerHTML={{ __html: item }} />
                    </Box>
                  );
                })}
              </Box>
            );
          }
          case 'delimiter': {
            return <Box py={1}> </Box>;
          }
          default:
            return null;
        }
      })}
    </>
  );
};

const Image = ({ data }) => {
  const { image } = useStyles({
    stretched: data.stretched,
    bordered: data.withBorder
  });
  return <img className={image} src={data.file.url} alt="" />;
};

const TextViewer = ({ content, asFaq }) => {
  return asFaq ? (
    <FaqViewer content={content} />
  ) : (
    <BlockViewer content={content} />
  );
};

export default TextViewer;
