import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  button: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.spacing(4),
    color: 'white',
    cursor: 'pointer',
    marginTop: theme.spacing(4),
    padding: `${theme.spacing(1.5)}px ${theme.spacing(8)}px`
  },
  root: {
    alignItems: 'center',
    backgroundImage: `url('images/campaign/campaign1-bg.jpg')`,
    backgroundSize: 'cover',
    backgroundPosition: 'bottom',
    display: 'flex',
    flexDirection: 'column',
    height: `calc(100vh - 60px)`,
    padding: `0px ${theme.spacing(4)}px`
  },
  subtitle: {
    color: 'white',
    marginTop: theme.spacing(2)
  },
  title: {
    color: 'white',
    lineHeight: '1.2em',
    marginTop: theme.spacing(12),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    }
  }
}));

const CampaignCover = ({ onEnter }) => {
  const { button, root, subtitle, title } = useStyles();
  return (
    <Box className={root}>
      <Typography className={title} variant="h3" align="center">
        ความปลอดภัย เริ่มได้ที่ตัวเรา
      </Typography>
      <Typography className={subtitle} variant="body1" align="center">
        ร่วมทำแบบสำรวจพฤติกรรมและประเมินความเสี่ยงต่อการติดเชื้อเอชไอวี
      </Typography>
      <Typography className={button} variant="h5" onClick={onEnter}>
        เริ่มทำได้เลย &gt;
      </Typography>
    </Box>
  );
};

export default CampaignCover;
