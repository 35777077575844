import React, { useEffect, useState, useCallback } from 'react';
import { Switch, Route, useHistory, useLocation } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';
import { Fab } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useCookies } from 'react-cookie';
import { menuMap, homeMenus } from 'common/menus';
import { addLog } from 'common/services/stat';
import { submitQuestionair } from 'common/services/feedback';

import Header from './components/Header';
import Footer from './components/Footer';

import Landing from './screens/Landing';
import Lgbt from './screens/Lgbt';
import Page from './screens/Page';
import Post from './screens/Post';
import GameCenter from './screens/GameCenter';
import Campaign1 from './screens/Campaign1';
import Campaign2 from './screens/Campaign2';
import CampaignTerms from './screens/CampaignTerms';
import SurveyFormDialog from './components/SurveyFormDialog';

const useStyles = makeStyles({
  goToTopBottom: {
    margin: 0,
    top: 'auto',
    right: 20,
    bottom: 20,
    left: 'auto',
    position: 'fixed'
  }
});

// add home route and turn menuMap into a route array
const routes: RouteProps[] = menuMap.map((menu: MenuData) => ({
  path: menu.link,
  page: menu.link === '/' ? 'home' : menu.link.replace('/', '')
}));

let pathname: string = '';
let lastScroll: number = 50000;
let showed: boolean = false;
const targetScroll = 500;
const App = () => {
  const history = useHistory();
  const location = useLocation();

  const [showSurvey, setShowSurvey] = useState(false);
  const [showScrollTop, setShowScrollTop] = useState(false);
  const [cookies, setCookie] = useCookies(['showSurvey']);

  const { goToTopBottom } = useStyles();

  useEffect(() => {
    // log site entrance
    addLog(navigator);
  }, []);

  const listenToScroll = useCallback(() => {
    if (
      homeMenus.indexOf(pathname) > -1 &&
      !showed &&
      lastScroll < window.pageYOffset &&
      window.pageYOffset - lastScroll < 100 &&
      window.pageYOffset >= targetScroll
    ) {
      showed = true;
      setShowSurvey(true);
    }
    if (!showScrollTop && window.pageYOffset > 400) {
      setShowScrollTop(true);
    } else if (showScrollTop && window.pageYOffset <= 400) {
      setShowScrollTop(false);
    }
    lastScroll = window.pageYOffset;
  }, [showScrollTop]);

  useEffect(() => {
    scroll.scrollToTop();
    pathname = history.location.pathname.substring(1);
    if (homeMenus.indexOf(pathname) > -1) {
      if (cookies.showSurvey) {
        showed = true;
      }
    }

    return () => {
      if (homeMenus.indexOf(pathname) > -1) {
        setShowSurvey(false);
        showed = false;
      }
    };
  }, [history.location.pathname, cookies.showSurvey]);

  useEffect(() => {
    window.addEventListener('scroll', listenToScroll);
    return () => {
      window.removeEventListener('scroll', listenToScroll);
    };
  }, [history.location.pathname, listenToScroll]);

  // log page entrance
  window.gtag('config', 'UA-59955229-5', {
    page_location: window.location.href,
    page_path: history.location.pathname
  });
  addLog(navigator, history.location.pathname);

  const handleFooterLinkClick = (web: string) => {
    switch (web) {
      case 'facebook':
        window.open('https://www.facebook.com/AIDSStation/');
        break;
      case 'youtube':
        window.open('https://www.youtube.com/user/BankIYaa');
        break;
      default:
        break;
    }
  };
  if (location.pathname === '/') {
    return (
      <>
        <Header hideMenu={true} />
        <Landing />
      </>
    );
  }

  return (
    <>
      <Header />
      <Switch>
        {routes.map((route) => (
          <Route
            key={route.page}
            path={route.path}
            render={(props) => <Page {...props} page={route.page} />}
            exact
          />
        ))}
        <Route path="/post/:id" component={Post} />
        <Route
          path="/general"
          render={(props) => <Page {...props} page="home" />}
        />
        <Route path="/lgbt" component={Lgbt} />
        <Route path="/game-center" component={GameCenter} />
        <Route path="/campaign1" component={Campaign1} />
        <Route path="/campaign02-2020" component={Campaign2} />
        <Route path="/campaign-terms" component={CampaignTerms} />
      </Switch>
      <Footer onIconClick={handleFooterLinkClick} />
      <SurveyFormDialog
        open={showSurvey}
        onSubmit={(result: any) => {
          submitQuestionair(result);
          setShowSurvey(false);
          setCookie('showSurvey', true);
        }}
        onSetOpen={(open: boolean) => setShowSurvey(open)}
      />
      {showScrollTop && (
        <Fab
          className={goToTopBottom}
          color="primary"
          variant="extended"
          onClick={() => scroll.scrollToTop()}
        >
          กลับด้านบน
        </Fab>
      )}
    </>
  );
};

export default App;
