export const items = [
  {
    title: 'หน่วยบริการ',
    content:
      'ค้นหาหน่วยบริการที่เกี่ยวกับโรคติดต่อทางเพศสัมพันธ์ เอชไอวี และเอดส์ ได้ที่นี่ ',
    background: 'images/home/bg-content-about.png',
    icon: 'images/home/icon-about.png',
    url: '/search'
  },
  {
    title: 'PrEP/PEP',
    content: `ยา PrEP (เพร็พ) คือ ยาต้านไวรัส สำหรับป้องกันการติดเชื้อเอชไอวีก่อนการสัมผัส
      ยา PEP (เป๊บ) คือ ยาต้านฉุกเฉิน เพื่อป้องกันการติดเชื้อเอชไอวี ต้องทานภายใน 72 ชั่วโมงหลังเสี่ยง และต่อเนื่อง 28 วัน`,
    background: 'images/home/bg-content-prep.png',
    icon: 'images/home/icon-01.png',
    url: '/preppep'
  },
  {
    title: 'ถุงยางอนามัยและสารหล่อลื่น',
    content:
      'การใช้ถุงยางและสารหล่อลื่นอย่างถูกวิธี สามารถป้องกัน HIV STIs และการท้องไม่พร้อม',
    background: 'images/home/bg-content-condom.png',
    icon: 'images/home/icon-02.png',
    url: '/condom'
  }
];
