import React from 'react';
import {
  FilledInput,
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: '300px'
  }
}));

const Selector = ({ choices, label, onSelect, selected }) => {
  const { formControl } = useStyles();
  return (
    <FormControl className={formControl} variant="filled">
      <InputLabel htmlFor="care-center">{label}</InputLabel>
      <Select
        value={selected}
        onChange={e => onSelect(e.target.value)}
        input={<FilledInput id="care-center" />}
      >
        {choices.map((choice, index) => (
          <MenuItem key={index} value={choice.value}>
            {choice.text}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default Selector;