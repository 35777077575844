import React from 'react';
import { Box, Grid, Hidden, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Paginator from './Paginator';

const useStyle = makeStyles(theme => ({
  link: {
    borderRadius: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column'
  },
  linkInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      minHeight: theme.spacing(15)
    }
  },
  linkSubtitle: {
    color: theme.palette.grey[500]
  },
  linkThumbnail: {
    backgroundImage: ({ image }) => `url('${image}')`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    borderRadius: '8px 8px 0px 0px',
    height: '200px'
  },
  linkTitle: {
    cursor: 'pointer'
  }
}));

const LinkList = ({
  items,
  currentPage,
  itemPerPage,
  onLinkClick,
  onPrevPage,
  onNextPage
}) => {
  return (
    <Box p={1}>
      <Grid container spacing={2}>
        {items
          .slice(currentPage * itemPerPage, (currentPage + 1) * itemPerPage)
          .map((item, index) => (
            <Grid key={`${currentPage}-${index}`} item xs={12} md={6} lg={3}>
              <LinkItem item={item} onClick={onLinkClick} />
            </Grid>
          ))}
      </Grid>
      {items.length > itemPerPage ? ( // paginator required only if total item doesn't fit in one page
        <Paginator
          currentPage={currentPage}
          itemPerPage={itemPerPage}
          totalItem={items.length}
          onPrevPage={onPrevPage}
          onNextPage={onNextPage}
        />
      ) : (
        <Box p={2} />
      )}
    </Box>
  );
};

const LinkItem = ({ item, onClick }) => {
  const getThumbnail = url => {
    if (url.includes('youtube.com')) {
      const parts = url.split('/');
      const videoId = parts[parts.length - 1];
      return `http://img.youtube.com/vi/${videoId}/0.jpg`;
    }
    return null;
  };
  const image = item.image || getThumbnail(item.url);
  const { link, linkInfo, linkSubtitle, linkThumbnail, linkTitle } = useStyle({
    image
  });
  return (
    <Paper className={link}>
      <Hidden smDown>
        <div className={linkThumbnail} />
      </Hidden>
      <div className={linkInfo}>
        <div className={linkTitle} onClick={() => onClick(item)}>
          <Typography variant="h6" color="primary">
            {item.title}
          </Typography>
        </div>
        <div className={linkSubtitle}>
          <Typography variant="caption">ที่มา - {item.detail}</Typography>
        </div>
      </div>
    </Paper>
  );
};

export default LinkList;
