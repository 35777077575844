import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { PlayCircleOutline } from '@material-ui/icons';
import clsx from 'clsx';
import VideoModal from './VideoModal';

const useStyles = makeStyles(theme => ({
  featuredImage: {
    backgroundBlendMode: 'multiply',
    backgroundColor: ({ hover, video }) => hover && video && '#666',
    backgroundImage: ({ image }) => `url('${image}')`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    cursor: ({ video }) => video && 'pointer',
    minHeight: '300px'
  },
  overlay: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    pointerEvents: 'none',
    position: 'absolute',
    top: 0,
    width: '100%'
  },
  reverse: { flexDirection: 'row-reverse' },
  section: theme.section,
  title: {
    fontWeight: 'bold',
    marginBottom: '8px'
  }
}));

const FeaturedPost = ({ swap, onClick, item }) => {
  const { excerpt, image, title, video } = item;
  const [hover, setHover] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const {
    featuredImage,
    overlay,
    reverse,
    section,
    title: titleText
  } = useStyles({
    hover,
    image,
    video
  });
  return (
    <Box padding={0.5}>
      <Grid
        className={clsx(section, { [reverse]: swap })}
        container
        spacing={1}
      >
        <Grid item xs={12} sm={6}>
          <Box bgcolor="common.white" borderRadius="8px" height="100%" p={4}>
            <Typography className={titleText} variant="h4">
              {title}
            </Typography>
            <Typography
              variant="subtitle1"
              paragraph
              dangerouslySetInnerHTML={{ __html: excerpt }}
            />
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => onClick(item)}
            >
              อ่านเพิ่มเติม
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} style={{ position: 'relative' }}>
          <Box
            className={featuredImage}
            borderRadius="8px"
            height="100%"
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onClick={() => setShowVideo(true)}
          />
          {video && (
            <Box className={overlay}>
              <PlayCircleOutline
                style={{ color: '#DDD', fontSize: 128, cursor: 'pointer' }}
              />
            </Box>
          )}
        </Grid>
      </Grid>
      <VideoModal
        videoUrl={video}
        open={showVideo}
        autoplay={true}
        onClose={() => setShowVideo(false)}
      />
    </Box>
  );
};

FeaturedPost.propTypes = {
  item: PropTypes.object.isRequired
};

FeaturedPost.defaultProps = {
  image: '',
  swap: false
};

export default FeaturedPost;
