import React from 'react';
import { makeStyles } from '@material-ui/styles';

const imageWidth = 1000;
const imageHeight = 1000;

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'flex-start',
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    height: ({ scale }) => imageHeight * scale,
    justifyContent: 'center',
    position: 'relative',
  },
  overlay: {
    fontFamily: ({ fontFamily }) => fontFamily,
    fontSize: ({ fontSize }) => `${fontSize}px`,
    left: ({ x }) => x,
    overflow: 'hidden',
    position: 'absolute',
    textAlign: 'center',
    top: ({ y }) => y,
    whiteSpace: 'nowrap',
    width: ({ width }) => width,
  },
}));

const Preview = ({ setting, messages }) => {
  const targetScale = 0.4; // scale that makes the image looks good on big screen
  const maxScale = window.innerWidth / imageWidth; // scale for small screen
  const scale = maxScale < targetScale ? maxScale : targetScale;
  const { root } = useStyles({ scale });
  return (
    <div className={root}>
      <div style={{ transform: `scale(${scale})`, transformOrigin: 'top' }}>
        <img src={setting.image} style={{ height: 1000 }} alt="" />
        {setting.lines.map((line, index) => (
          <Message key={index} line={line} message={messages[index]} />
        ))}
      </div>
    </div>
  );
};

const Message = ({ line, message }) => {
  const { overlay } = useStyles({ ...line });
  return <div className={overlay}>{message}</div>;
};

export default Preview;
