import React from 'react';
import {
  Button,
  Grid,
  Typography,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Selector from './Selector';
import Icon from './Icon';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '16px 0px'
  },
  title: {
    display: 'flex'
  }
}));

const SearchHeader = ({
  icon,
  title,
  subtitle,
  primaryLabel,
  primaryChoices,
  secondaryLabel,
  secondaryChoices,
  onPrimarySelect,
  onSecondarySelect,
  primarySelected,
  secondarySelected,
  onSearch,
  filter,
  contactFilter,
  filterSSO,
  setFilterSSO,
  filterOF,
  setFilterOF,
  filterGold,
  setFilterGold,
  filterOverTime,
  setFilterOverTime,
  filterTrain,
  setFilterTrain
}) => {
  const { root, title: titleStyle } = useStyles();

  const renderOptions = () => (
    <>
      <FormControlLabel
        control={
          <Checkbox
            value={filterSSO}
            defaultChecked={false}
            onChange={e => setFilterSSO(e.target.checked)}
          />
        }
        label={<Typography variant="caption">สิทธิประกันสังคม</Typography>}
      />
      <FormControlLabel
        control={
          <Checkbox
            value={filterGold}
            defaultChecked={false}
            onChange={e => setFilterGold(e.target.checked)}
          />
        }
        label={<Typography variant="caption">สิทธิบัตรทอง</Typography>}
      />
      <FormControlLabel
        control={
          <Checkbox
            value={filterOF}
            defaultChecked={false}
            onChange={e => setFilterOF(e.target.checked)}
          />
        }
        label={<Typography variant="caption">สิทธิข้าราชการ</Typography>}
      />
      <FormControlLabel
        control={
          <Checkbox
            value={filterOverTime}
            defaultChecked={false}
            onChange={e => setFilterOverTime(e.target.checked)}
          />
        }
        label={<Typography variant="caption">นอกเวลาราชการ</Typography>}
      />
      <FormControlLabel
        control={
          <Checkbox
            value={filterTrain}
            defaultChecked={false}
            onChange={e => setFilterTrain(e.target.checked)}
          />
        }
        label={<Typography variant="caption">ใกล้ BTS, MRT</Typography>}
      />
    </>
  );

  return (
    <Grid className={root} spacing={1} container>
      <Grid item xs={12} md={filter ? 3 : 'auto'}>
        <div className={titleStyle}>
          <Icon icon={icon} />
          <div style={{ marginTop: '-8px', padding: '0px 16px' }}>
            <Typography variant="h6">{title}</Typography>
            <Typography variant="subtitle2">{subtitle}</Typography>
          </div>
        </div>
      </Grid>
      {filter && (
        <>
          <Grid item xs={12} md="auto">
            <Grid container spacing={1}>
              <Grid item>
                <Selector
                  label={primaryLabel}
                  choices={primaryChoices}
                  selected={primarySelected}
                  onSelect={onPrimarySelect}
                />
              </Grid>
              <Grid item>
                <Selector
                  label={secondaryLabel}
                  choices={secondaryChoices}
                  selected={secondarySelected}
                  onSelect={onSecondarySelect}
                />
              </Grid>
            </Grid>
            {contactFilter && renderOptions()}
          </Grid>
          <Grid item xs={12} md="auto">
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={onSearch}
            >
              ค้นหา
            </Button>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default SearchHeader;
