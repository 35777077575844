export const baseMap = {
  defaultCenter: {
    lat: 13.720417,
    lng: 100.500583
  },
  defaultZoom: 11,
  icon:
    'https://firebasestorage.googleapis.com/v0/b/progaming-bkkhive.appspot.com/o/map%2Fmarker.png?alt=media&token=e0d283b6-ac1b-49dc-a31e-5eb3d3b4c240',
  selectedIcon:
    'https://firebasestorage.googleapis.com/v0/b/progaming-bkkhive.appspot.com/o/map%2Fmarker-active.png?alt=media&token=a0814fc2-a1bd-49cf-b21d-b94bef38581f'
};
