import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  Divider,
  Icon,
  Paper
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Home, PlayCircleOutline } from '@material-ui/icons';
import { FacebookShareButton } from 'react-share';
import clsx from 'clsx';
import Title from './Title';
import TextViewer from './TextViewer';
import VideoModal from './VideoModal';

const useStyles = makeStyles(theme => ({
  banner: {
    backgroundBlendMode: 'multiply',
    backgroundColor: ({ hover, video }) => hover && video && '#666',
    backgroundImage: ({ image }) => `url('${image}')`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    cursor: ({ video }) => video && 'pointer',
    height: '320px',
    width: '100%'
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  },
  overlay: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    pointerEvents: 'none',
    position: 'absolute',
    top: 0,
    width: '100%'
  }
}));

const SinglePost = ({ data, history }) => {
  const { content, image, title, video } = data;
  const [hover, setHover] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const { banner, buttonIcon, overlay } = useStyles({ image, hover, video });
  const { location } = window;
  const shareUrl = location.href;

  return (
    <Container maxWidth="md">
      <Box component={Paper} my={2}>
        <Box display="flex" boxShadow={1} style={{ position: 'relative' }}>
          <Box
            className={banner}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onClick={() => setShowVideo(true)}
          />
          {video && (
            <Box className={overlay}>
              <PlayCircleOutline
                style={{ color: '#DDD', fontSize: 128, cursor: 'pointer' }}
              />
            </Box>
          )}
        </Box>
        <Box p={2}>
          <Title text={title} />
          <TextViewer content={content} />
        </Box>
        <Divider />
        <Box p={2} display="flex" justifyContent="space-between">
          <Button
            variant="outlined"
            color="primary"
            onClick={() => history.push('/')}
          >
            <Home className={buttonIcon} fontSize="small" /> กลับหน้าหลัก
          </Button>
          <FacebookShareButton url={shareUrl}>
            <Button variant="contained" color="secondary">
              <Icon
                className={clsx(buttonIcon, 'fab fa-facebook-f')}
                style={{ fontSize: '12px' }}
              />
              แชร์
            </Button>
          </FacebookShareButton>
        </Box>
      </Box>
      <VideoModal
        videoUrl={video}
        open={showVideo}
        autoplay={true}
        onClose={() => setShowVideo(false)}
      />
    </Container>
  );
};

export default withRouter(SinglePost);
