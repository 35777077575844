export const items = [
    {
      title: 'เอชไอวีและเอดส์',
      image: 'images/home/btn-1.png',
      url: '/hiv'
    },
    {
      title: 'ค้นหาสถานพยาบาล',
      image: 'images/home/btn-2.png',
      url: '/service'
    },
    {
      title: 'PrEP & PEP',
      image: 'images/home/btn-3.png',
      url: '/preppep'
    },
    {
      title: 'ถุงยางอนามัย',
      image: 'images/home/btn-4.png',
      url: '/condom'
    },
    {
      title: 'ไม่ตีตรา',
      image: 'images/home/btn-5.png',
      url: '/s-and-d'
    },
    {
      title: 'วัณโรค',
      image: 'images/home/btn-6.png',
      url: '/tb'
    }
  ];