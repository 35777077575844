import { database, api, fieldValue } from '../firebase';
import axios from 'axios';
import download from 'downloadjs';

const fetch = async (collection, doc) => {
  try {
    if (doc) {
      const res = await database
        .collection(collection)
        .doc(doc)
        .get();
      if (res.exists) {
        return { ...res.data(), id: res.id };
      }
      return {};
    } else {
      const res = await fetchAll(collection);
      return res;
    }
  } catch (error) {
    throw error;
  }
};

const fetchOnlineAppointment = async () => {
  try {
    const res = await fetch('subContents', 'online-appointment');
    return res;
  } catch (error) {
    throw error;
  }
};

const fetchAll = async collection => {
  try {
    const res = await database.collection(collection).get();
    let data = [];
    res.docs.forEach(
      doc =>
        (data = [
          ...data,
          {
            ...doc.data(),
            id: doc.id
          }
        ])
    );
    return data;
  } catch (error) {
    throw error;
  }
};
const save = async (collection, data) => {
  try {
    let id = data.id;
    if (data.id) {
      let contact = { ...data, updatedAt: fieldValue.serverTimestamp() };
      delete contact.id;
      await database
        .collection(collection)
        .doc(data.id)
        .set(contact);
    } else {
      const res = await database.collection(collection).add({
        ...data,
        createdAt: fieldValue.serverTimestamp(),
        updatedAt: fieldValue.serverTimestamp()
      });
      id = res.id;
    }

    return id;
  } catch (error) {
    throw error;
  }
};
const deleteData = async (collection, data) => {
  try {
    await database
      .collection(collection)
      .doc(data.id)
      .delete();
  } catch (error) {
    throw error;
  }
};

const importData = async (collection, file) => {
  const formData = new FormData();
  formData.append('file', file);
  try {
    await axios({
      method: 'post',
      url: `${api}/items/import/${collection}/`,
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data: formData
    });
    return true;
  } catch (e) {
    throw e;
  }
};

const exportData = async collection => {
  try {
    const res = await axios({
      method: 'get',
      url: `${api}/items/${collection}`,
      headers: {
        'access-control-allow-origin': '*'
      },
      responseType: 'blob'
    });
    download(res.data, `${collection}.xlsx`, res.headers['content-type']);
    console.log('res:', res.data);
  } catch (error) {
    throw error;
  }
};

const exportFeedbackData = async () => {
  try {
    const res = await axios({
      method: 'get',
      url: `${api}/feedbacks/export`,
      headers: {
        'access-control-allow-origin': '*'
      },
      responseType: 'blob'
    });
    download(res.data, `feedbacks.xlsx`, res.headers['content-type']);
    console.log('res:', res.data);
  } catch (error) {
    throw error;
  }
};

export default {
  deleteData,
  fetch,
  fetchAll,
  fetchOnlineAppointment,
  importData,
  save,
  exportData,
  exportFeedbackData
};
