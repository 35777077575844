import React, { useState, useEffect, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { animateScroll as scroll } from 'react-scroll';
import moment from 'moment';
import { useCookies } from 'react-cookie';

import EmptyLoading from '../components/EmptyLoading';
import SinglePage from '../components/SinglePage';
import CampaignPopup from '../components/CampaignPopup';

import PageService from 'common/services/page';
import CloudService from 'common/services/cloud';

const campaingInfo = {
  title: 'กิจกรรม ชวนเพื่อน TEST',
  subtitle: `กิจกรรมดีดีต้องบอกต่อ กิจกรรม "ชวนเพื่อน TEST" ลุ้นรับตั๋วหนังฟรีๆ ง่ายนิดเดียว`,
  mediaUrl: '/images/campaign/campaign28-popup.jpg',
  postUrl: '/post/ahDnt3eRkufgtQ7uVLxT'
};

const Page = ({ page }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [popupData, setPopupData] = useState({});
  const [specialTags, setSpecialTags] = useState([]);
  const [openVideoDialog, setOpenVideoDialog] = useState(false);
  const [cookies, setCookie] = useCookies(['showVideo']);
  const cookiesShowVideoTime = 0; // TODO: 0 minute just to always show it

  const fetch = useCallback(async () => {
    setLoading(true);
    const res = await PageService.fetchDev(page);
    const data = await PageService.resolve(res);
    const specialTags = await PageService.resolveSpecialTags(data);
    setSpecialTags(specialTags);
    setData(data);
    setLoading(false);
    scroll.scrollToTop();
  }, [page]);

  useEffect(() => {
    fetch();
    if (page.includes('home') && !cookies.showVideo) {
      setCookie('showVideo', moment(), {
        expires: moment().add(cookiesShowVideoTime, 'minutes').toDate()
      });
      CloudService.fetch('settings', 'popup').then((item) => {
        setPopupData(item);
        console.log('item: ', item);
        if (item.active) setOpenVideoDialog(true);
      });
    }
  }, [cookies.showVideo, fetch, page, setCookie]);

  if (loading) {
    return <EmptyLoading />;
  }
  return (
    <>
      <Helmet>
        <meta property="og:type" content="website" />
        <meta property="og:title" content="BKK Hive" />
        <meta
          property="og:description"
          content="ศูนย์รวมเรื่องเอชไอวี เอดส์ และโรคติดต่อทางเพศสัมพันธ์"
        />
        {/* <meta property="og:url" content="http://www.bkkhive.com" /> */}
        {/* <meta property="og:image" content="https://via.placeholder.com/150" /> */}
      </Helmet>
      <SinglePage data={data} specialTags={specialTags} />
      {page.includes('home') && (
        <CampaignPopup
          {...popupData}
          open={openVideoDialog}
          onClose={() => setOpenVideoDialog(false)}
        />
      )}
    </>
  );
};

export default Page;
