import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  Icon,
  Link,
  Typography,
  Chip
} from '@material-ui/core';
import {
  PinDrop,
  AccessTime,
  Call,
  AccountBox,
  Mail,
  Language,
  Launch,
  DirectionsSubway
} from '@material-ui/icons';
import clsx from 'clsx';
import DialogTitle from './DialogTitle';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  buttonGrid: {
    display: 'flex',
    marginTop: theme.spacing(4),
    justifyContent: 'center'
  },
  dialogContent: {
    marginBottom: theme.spacing(2)
  },
  icon: {
    color: theme.palette.primary.main,
    marginRight: theme.spacing(1)
  },
  fLogo: {
    marginRight: theme.spacing(1),
    paddingLeft: '3px'
  },
  gridItem: {
    display: 'flex',
    alignItems: 'center'
  },
  chip: {
    margin: theme.spacing(1)
  }
}));

const DialogContact = ({
  open,
  handleClose,
  image,
  title,
  detail,
  address,
  hours,
  contact,
  tel,
  contactPerson,
  email,
  website,
  facebook,
  train,
  latitude,
  longitude,
  extra
}) => {
  const {
    buttonGrid,
    dialogContent,
    icon,
    gridItem,
    fLogo,
    chip
  } = useStyles();

  const getExtraText = str => {
    switch (str) {
      case 'y':
        return 'เยาวชน';
      case 'm':
        return 'ชายรักชาย';
      case 't':
        return 'คนข้ามเพศ';
      default:
        return '';
    }
  };

  const formatEscapedText = (str, keyPrefix) =>
    str &&
    str
      .split('\\n')
      .map((str, index) => (
        <Typography key={`${keyPrefix}-${index}`}>{str}</Typography>
      ));

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
      <DialogTitle onClose={handleClose} />
      <DialogContent className={dialogContent}>
        <Grid container spacing={2}>
          <Grid item sm={4}>
            <Box boxShadow={1}>
              <img
                src={image || 'images/tempImages/contact-1.png'}
                alt=""
                style={{ maxWidth: '100%' }}
              />
            </Box>
          </Grid>
          <Grid item sm={8}>
            <Typography variant="h6">{title}</Typography>
            {formatEscapedText(detail, 'd')}
          </Grid>
          <Grid item xs={12} className={gridItem} style={{ marginTop: '8px' }}>
            <PinDrop className={icon} />
            <Typography>{address}</Typography>
          </Grid>
          {train && train !== '-' && (
            <Grid item xs={12} className={gridItem}>
              <DirectionsSubway className={icon} />
              <Typography component="div">
                {formatEscapedText(train, 't')}
              </Typography>
            </Grid>
          )}
          <Grid item sm={12} className={gridItem}>
            <AccessTime className={icon} />
            <Typography component="div">
              {formatEscapedText(hours, 'h')}
            </Typography>
          </Grid>
          <Grid item xs={12} className={gridItem}>
            <AccountBox className={icon} />
            <Typography component="div">
              {contactPerson &&
                contactPerson
                  .split('\\n')
                  .map((str, index) => <Box key={`c-${index}`}>{str}</Box>)}
            </Typography>
          </Grid>
          <Grid item sm={6} className={gridItem}>
            <Call className={icon} />
            <Typography>{contact}</Typography>
          </Grid>
          <Grid item xs={6} className={gridItem}>
            <Mail className={icon} />
            <Typography>{email}</Typography>
          </Grid>
          {website && website !== '-' && (
            <Grid item xs={6} className={gridItem}>
              <Language className={icon} />
              <Link variant="body1" href={website} target="_blank">
                เปิดเว็บไซต์
              </Link>
            </Grid>
          )}
          {facebook && facebook !== '' && (
            <Grid item xs={6} className={gridItem}>
              <Icon
                className={clsx(fLogo, 'fab fa-facebook-square')}
                color="primary"
                fontSize="small"
              />
              <Link variant="body1" href={facebook} target="_blank">
                เพจเฟสบุ๊ค
              </Link>
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} className={buttonGrid}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            href={`https://www.google.com/maps?ll=${latitude},${longitude}&q=${title}&amp;z=14`}
            target="_blank"
          >
            เปิดแผนที่
            <Box mr={1} />
            <Launch />
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default DialogContact;
