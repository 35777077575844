import React, { useState } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Room, PermPhoneMsg } from '@material-ui/icons';
import MyGoogleMap from './MyGoogleMap';

const defaultMap = {
  defaultCenter: { lat: 13.720402, lng: 100.500578 },
  defaultZoom: 15,
  icon:
    'https://firebasestorage.googleapis.com/v0/b/progaming-bkkhive.appspot.com/o/articles%2Fmap%2Fmarker.png?alt=media&token=5cbddc88-9cca-418d-9de8-9f74253714e0'
};
const office = {
  lat: 13.720402,
  lng: 100.500578,
  title: 'กองควบคุมโรคเอดส์',
  district: 'คลองสาน',
  address:
    '124/16 ถนนกรุงธนบุรี แขวงบางลำภูล่าง เขตคลองสาน กรุงเทพมหานคร 10600',
  tel: '02 860 8751-6',
  hours: '8.00-16.00น.'
};

const useStyles = makeStyles(theme => ({
  topic: {
    color: '#19315B'
  },
  contactGrid: {
    display: 'flex',
    padding: theme.spacing(2)
  }
}));

const AboutUsMap = () => {
  const [selectedLocation, setSelectedLocation] = useState(null);
  const { contactGrid } = useStyles();
  return (
    <>
      <Grid container spacing={3} className={contactGrid}>
        <Grid item xs={12} sm={6}>
          <ContactGrid
            icon={<Room color="primary" />}
            title="สถานที่ตั้ง"
            detail="กองควบคุมโรคเอดส์ วัณโรคและโรคติดต่อทางเพศสัมพันธ์ 124/16 ถนนกรุงธนบุรี แขวงบางลำภูล่าง เขตคลองสาน กรุงเทพมหานคร 10600"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ContactGrid
            icon={<PermPhoneMsg color="primary" />}
            title="เบอร์ติดต่อ"
            detail="Call Center 02 860 8751-6"
          />
        </Grid>
      </Grid>
      <MyGoogleMap
        searchHeader={false}
        defaultZoom={defaultMap.defaultZoom}
        defaultCenter={defaultMap.defaultCenter}
        icon={{
          url: defaultMap.icon,
          scaledSize: { width: 27, height: 32 }
        }}
        markers={[office]}
        onMarkerClick={marker => setSelectedLocation(marker)}
        selected={selectedLocation}
        height="500px"
        onClickDirection={info =>
          window.open(
            `https://www.google.com/maps?ll=${info.lat},${info.lng}&q=${
              info.title
            }&amp;z=14`,
            '_blank'
          )
        }
      />
    </>
  );
};

const ContactGrid = ({ icon, title, detail }) => {
  const { topic } = useStyles();
  return (
    <Box display="flex">
      <Box mr={1} mt={0.25}>
        {icon}
      </Box>
      <Box>
        <Typography variant="h6" className={topic}>
          {title}
        </Typography>
        <Typography variant="subtitle2">{detail}</Typography>
      </Box>
    </Box>
  );
};

export default AboutUsMap;
