import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  DialogTitle as MuiDialogTitle,
  Typography,
  IconButton
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    minHeight: '64px'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.primary.main
  }
}));

const DialogTitle = ({ children, onClose }) => {
  const { root, closeButton } = useStyles();
  return (
    <MuiDialogTitle disableTypography className={root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="Close"
          className={closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

export default DialogTitle;
