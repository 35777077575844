import React from 'react';
import { withRouter } from 'react-router-dom';
import { Box, Container, Paper, Modal, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Close } from '@material-ui/icons';

const offset = 7.05;

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    top: `2%`,
    right: `${offset + 2}%`
  },
  container: {
    height: '750px',
    maxHeight: '80%',
    padding: '0px 0px',
    position: 'relative'
  },
  content: {
    height: '101%',
    position: 'absolute',
    left: `${offset}%`,
    overflowY: 'auto',
    top: '0px',
    width: `${100 - offset * 2}%`
  },
  foreground: {
    backgroundImage: `url('images/home/campaign-popup.png')`,
    backgroundPosition: 'bottom',
    backgroundSize: '960px 377px',
    [theme.breakpoints.down('sm')]: {
      backgroundSize: 'contain'
    },
    backgroundRepeat: 'no-repeat',
    height: '100%',
    position: 'absolute',
    top: '0px',
    width: '100%'
  },
  paper: {
    backgroundColor: '#FFD7D7',
    height: '100%',
    margin: '0px auto',
    width: `${100 - offset * 2}%`
  },
  paperRoot: {
    borderRadius: '16px'
  },
  root: {
    alignItems: 'center',
    display: 'flex',
    height: '100vh',
    justifyContent: 'center'
  },
  video: {
    height: '100%',
    left: '0px',
    position: 'absolute',
    top: '0px',
    width: '100%'
  },
  videoContainer: {
    height: '0px',
    overflow: 'hidden',
    paddingBottom: '56.25%',
    paddingTop: '30px',
    position: 'relative'
  }
}));

const CampaignPopup = ({ history, image, link, open, onClose }) => {
  const {
    closeButton,
    container,
    content,
    foreground,
    paper,
    paperRoot,
    root,
    video,
    videoContainer
  } = useStyles();
  return (
    <Modal
      className={root}
      open={open}
      onClose={onClose}
      disableAutoFocus={true}
    >
      <Container className={container} maxWidth="md">
        <Paper className={paper} classes={{ root: paperRoot }} />
        <Box className={foreground} />
        <Box className={content}>
          <Box width="90%" mx="auto" pt={3} pb={1}>
            <Box mx={6}>
              <img src={image} style={{ width: '100%' }} alt="popup" />
              {/* {mediaUrl.includes('youtube') ? (
                <Box className={videoContainer}>
                  <iframe
                    className={video}
                    title="campaign"
                    width="720"
                    height="405"
                    src={`${mediaUrl}`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </Box>
              ) : (
                <img src={mediaUrl} style={{ width: '100%' }} alt="popup" />
              )} */}
            </Box>
            <Box my={2} display="flex" justifyContent="center">
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  link.startsWith('/')
                    ? history.push(link)
                    : window.open(link, '_blank')
                }
              >
                อ่านเพิ่มเติม
              </Button>
            </Box>
          </Box>
        </Box>
        <Button className={closeButton} onClick={onClose}>
          <Close />
        </Button>
      </Container>
    </Modal>
  );
};

export default withRouter(CampaignPopup);
