import React from 'react';
import { Grid, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Phone, Language } from '@material-ui/icons';
import Paginator from './Paginator';

const useStyles = makeStyles(theme => ({
  contact: {
    display: 'flex',
    borderRadius: '8px'
  },
  contactInfo: {
    display: 'flex',
    flexDirection: 'column',
    padding: '8px 16px',
    width: `calc(100% - 100px)`
  },
  contactTel: {
    color: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center'
  },
  contactThumbnail: {
    backgroundImage: ({ image }) => `url('${image}')`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    borderRadius: '8px 0px 0px 8px',
    height: '100px',
    minWidth: '100px'
  },
  contactTitle: {
    ...theme.truncatedText,
    cursor: 'pointer',
    minHeight: '40px'
  },
  contactWeb: {
    color: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center'
  },
  contactUrl: theme.truncatedText,
  icon: {
    marginRight: '8px'
  },
  root: {},
  title: {
    display: 'flex',
    padding: theme.spacing(4)
  },
  titleIcon: {}
}));

const ContactList = ({
  items,
  currentPage,
  itemPerPage,
  onItemClick,
  onPrevPage,
  onNextPage,
  onClickTel,
  onClickWeb
}) => {
  const { root } = useStyles();
  return (
    <div className={root}>
      <Grid container spacing={2}>
        {items
          .slice(currentPage * itemPerPage, (currentPage + 1) * itemPerPage)
          .map((item, index) => (
            <Grid item xs={12} md={6} lg={4} key={index}>
              <ContactItem
                item={item}
                onClick={onItemClick}
                onClickTel={onClickTel}
                onClickWeb={onClickWeb}
              />
            </Grid>
          ))}
      </Grid>
      {items.length > itemPerPage ? ( // paginator required only if total item doesn't fit in one page
        <Paginator
          currentPage={currentPage}
          itemPerPage={itemPerPage}
          totalItem={items.length}
          onPrevPage={onPrevPage}
          onNextPage={onNextPage}
        />
      ) : (
        <div style={{ height: '32px' }} />
      )}
    </div>
  );
};

const ContactItem = ({ item, onClick, onClickTel, onClickWeb }) => {
  const {
    contact,
    contactInfo,
    contactThumbnail,
    contactTel,
    contactTitle,
    contactWeb,
    contactUrl,
    icon
  } = useStyles({
    image: item.image
  });
  return (
    <Paper className={contact}>
      <div className={contactThumbnail} />
      <div className={contactInfo}>
        <div className={contactTitle} onClick={() => onClick(item)}>
          {item.title}
        </div>
        <div className={contactTel} onClick={() => onClickTel(item.tel)}>
          <Phone className={icon} fontSize="inherit" />
          {item.tel}
        </div>
        <div className={contactWeb} onClick={() => onClickWeb(item.website)}>
          {item.website && item.website !== '-' && item.website !== '' && (
            <>
              <Language className={icon} fontSize="inherit" />
              <div className={contactUrl} style={{}}>
                {item.website}
              </div>
            </>
          )}
        </div>
      </div>
    </Paper>
  );
};

export default ContactList;
