import React, { useState } from 'react';
import { Box, Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
  item: {
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: '4px',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
    padding: '16px',
    transform: ({hover}) => `translateY(${ hover ? '-4px' : '0px'})`
  },
  root: {
    minHeight: '130px'
  },
  section: theme.section
}));

const HotMenus = ({ items, onItemClick }) => {
  const { section, root } = useStyles();
  return (
    <Box p={0.5}>
      <Grid className={classNames(section, root)} container spacing={1}>
        {items.map((item, index) => (
          <Grid key={index} item xs>
            <HotMenu {...item} onClick={onItemClick} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export const HotMenu = ({ image, title, url, onClick }) => {
  const [hover, setHover] = useState(false);
  const { item } = useStyles({ hover });
  return (
    <Paper
      className={item}
      onClick={() => onClick(url)}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <img src={`${image}`} alt="" />
      <Typography>{title}</Typography>
    </Paper>
  );
};

export default HotMenus;
