import _ from 'lodash';

import districtJson from '../data/district_th.json';
import contactTagsJson from '../data/contact_tags.json';

let districtsArr = [];

const districts = () => {
  if (districtsArr.length === 0) {
    districtsArr = districtJson.th.amphoes
      .map(district => ({
        text: district.name,
        value: district.name
      }))
      .sort((a, b) => {
        if (a.text < b.text) {
          return -1;
        }
        if (a.text > b.text) {
          return 1;
        }
        return 0;
      });
  }
  return districtsArr;
};
const contactTagsByPage = page =>
  contactTagsJson.tags.filter(tag => _.includes(tag.pages, page));

export default {
  districts,
  contactTagsByPage
};
