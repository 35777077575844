import { database } from '../firebase';
import CloudService from './cloud';
import _ from 'lodash';

const PAGES = 'pages';

const fetch = async page => {
  try {
    const res = await database
      .collection('articles')
      .doc(page)
      .get();
    if (res.exists) {
      return res.data();
    }
    return {};
  } catch (error) {
    throw error;
  }
};

const fetchAll = async () => {
  try {
    const res = await database.collection('articles').get();
    let data = [];
    res.docs.forEach(doc => (data = [...data, { ...doc.data(), id: doc.id }]));
    return data;
  } catch (error) {
    throw error;
  }
};

const fetchDev = async page => {
  try {
    const res = await database
      .collection(PAGES)
      .doc(page)
      .get();
    if (res.exists) {
      return res.data();
    }
    return {};
  } catch (error) {
    throw error;
  }
};

const save = async data => {
  try {
    const { id } = data;
    let articleData = { ...data };
    delete articleData.id;
    await database
      .collection(PAGES)
      .doc(id)
      .set(articleData);
  } catch (error) {
    throw error;
  }
};

const resolvableTypes = ['appointment', 'file'];
const resolvableObjects = ['link', 'contact', 'mapwithcontact', 'video'];
const extractKey = blocks => {
  let list = [];
  blocks.forEach((block, index) => {
    const { content, type } = block;
    if (resolvableTypes.includes(type)) {
      list = [...list, { collection: `${type}s`, doc: content }];
    } else if (type === 'post') {
      if (block.allPost) {
        list = [...list, { collection: 'posts', type: 'allPost', index }];
      } else {
        console.log('content', content);
        if (content) {
          content.forEach(postId => {
            list = [...list, { collection: 'posts', doc: postId }];
          });
        }
      }
    } else if (resolvableObjects.includes(type)) {
      // special case for map with contact that get data from contacts collection
      const collection = type === 'mapwithcontact' ? 'contacts' : `${type}s`;
      list = [...list, { collection, doc: content.category }];
    }
  });
  return list;
};

const zipValue = (blocks, list) => {
  return blocks.map((block, index) => {
    const { content, type } = block;
    if (resolvableTypes.includes(type)) {
      const resolved = list.find(item => content === item.doc);
      return { ...block, content: resolved.data.items };
    }
    if (type === 'post') {
      let resolved = [];
      if (block.allPost) {
        resolved = list.find(item => item.index === index).data;
        resolved.sort((a, b) => {
          //descending
          if (a.createdAt.seconds < b.createdAt.seconds) {
            return 1;
          }
          if (a.createdAt.seconds > b.createdAt.seconds) {
            return -1;
          }
          return 0;
        });
      } else {
        resolved = content
          ? content.map(postId => {
              return list.find(item => postId === item.doc).data;
            })
          : [];
      }
      return { ...block, content: resolved };
    }
    if (resolvableObjects.includes(type)) {
      const resolved = list.find(item => content.category === item.doc);
      const merged = {
        ...content,
        items: resolved.data.items,
        name: resolved.data.name
      };
      return { ...block, content: merged };
    }
    return block;
  });
};

const resolve = async data => {
  // extract only resolvable value from the input
  let extracted = extractKey(data.blocks);
  // resolve!!
  const promises = extracted.map(item =>
    CloudService.fetch(item.collection, item.doc)
  );
  const results = await Promise.all(promises);
  // merge resolved data back to the list
  for (let i = 0; i < extracted.length; i++) {
    extracted[i].data = results[i];
  }
  // replace resolvable value with resolved data
  const blocks = zipValue(data.blocks, extracted);
  return { blocks };
};

const resolveSpecialTags = async data => {
  let categories = [];
  let promises = [];
  data.blocks
    .filter(block => block.content.specialTag)
    .forEach(block => {
      Object.entries(block.content.specialTags).forEach(([key, value]) => {
        if (!_.includes(categories, value)) {
          categories = [...categories, value];
          promises = [...promises, CloudService.fetch('contacts', value)];
        }
      });
    });
  const res = await Promise.all(promises);
  return res;
};

export default {
  fetch,
  fetchAll,
  fetchDev,
  resolve,
  save,
  resolveSpecialTags
};
