import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

const config = {
  apiKey: 'AIzaSyARsQnd5354ShCj9jKXmeYWXjanO2xTbiQ',
  authDomain: 'progaming-bkkhive.firebaseapp.com',
  databaseURL: 'https://progaming-bkkhive.firebaseio.com',
  projectId: 'progaming-bkkhive',
  storageBucket: 'progaming-bkkhive.appspot.com'
};
firebase.initializeApp(config);

const auth = firebase.auth();
const database = firebase.firestore();
const storage = firebase.storage();
const fieldValue = firebase.firestore.FieldValue;

const api = 'https://us-central1-progaming-bkkhive.cloudfunctions.net';

export { auth, database, storage, api, fieldValue };
