import React from 'react';
import { Box, Grid, Hidden, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
  item: {
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: '4px',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
    padding: '16px',
    transform: ({ hover }) => `translateY(${hover ? '-4px' : '0px'})`
  },
  root: {
    minHeight: '130px'
  },
  section: theme.section,
  link: {
    borderRadius: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer'
  },
  linkInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: theme.spacing(2)
  },
  linkThumbnail: {
    backgroundImage: ({ image }) => `url('${image}')`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    borderRadius: '8px 8px 0px 0px',
    height: '200px'
  }
}));

const HotMenusTransgender = ({ items, onItemClick }) => {
  const { section, root } = useStyles();
  return (
    <Box p={0.5}>
      <Grid className={classNames(section, root)} container spacing={1}>
        {items.map((item, index) => (
          <Grid key={index} item xs>
            <HotMenu {...item} onClick={onItemClick} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

const HotMenu = ({ title, image, url, onClick }) => {
  const { link, linkInfo, linkThumbnail } = useStyles({
    image
  });
  return (
    <Paper className={link} onClick={() => onClick(url)}>
      <Hidden smDown>
        <div className={linkThumbnail} />
      </Hidden>
      <div className={linkInfo}>
        <Typography variant="h6" align="center">
          {title}
        </Typography>
      </div>
    </Paper>
  );
};

export default HotMenusTransgender;
