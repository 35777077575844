import React, { useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';


const useStyles = makeStyles((theme) => ({
  clickable: { cursor: 'pointer' },
  responsiveImage: theme.responsiveImage,
  section: theme.section,
  root: {
    position: 'relative'
  },
  paginationRoot: {
    position: 'absolute',
    bottom: 8,
    right: 8,
    display: 'flex',
    flexDirection: 'row'
  },
  dot: {
    backgroundColor: '#e4e6e7',
    height: 12,
    width: 12,
    borderRadius: 6,
    margin: 3
  },
  dotActive: {
    backgroundColor: '#319fd6'
  }
}));

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
const BannerSwipeable = ({ items, onItemClick }) => {
  const [index, setIndex] = useState(0);
  const { root } = useStyles();
  const handleChangeIndex = (index) => {
    setIndex(index);
  };
  return (
    <div className={root}>
      <AutoPlaySwipeableViews
        enableMouseEvents
        interval={5000}
        index={index}
        onChangeIndex={handleChangeIndex}
      >
        {items.map((item, index) => (
          <SwipeableItem {...item} key={index} onItemClick={onItemClick} />
        ))}
      </AutoPlaySwipeableViews>
      <Pagination
        dots={items ? items.length : 0}
        index={index}
        onChangeIndex={handleChangeIndex}
      />
    </div>
  );
};

const SwipeableItem = ({ image, section, link, onItemClick }) => {
  const { clickable, responsiveImage, section: sectionStyle } = useStyles({
    link
  });
  return (
    <img
      className={classNames(responsiveImage, {
        [clickable]: link,
        [sectionStyle]: section
      })}
      src={image}
      alt=""
      onClick={() => link && link !== '' && onItemClick(link)}
    />
  );
};

const Pagination = ({ onChangeIndex, index, dots }) => {
  const { paginationRoot } = useStyles();
  const handleClick = (event, index) => {
    onChangeIndex(index);
  };
  const getDots = () => {
    const children = [];

    for (let i = 0; i < dots; i += 1) {
      children.push(
        <PaginationDot
          key={i}
          index={i}
          active={i === index}
          onClick={handleClick}
        />
      );
    }
    return children;
  };
  return <div className={paginationRoot}>{getDots()}</div>;
};

const PaginationDot = ({ onClick, index, active }) => {
  const { dot, dotActive } = useStyles();
  const handleClick = (e) => {
    onClick(e, index);
  };

  return (
    <Button
      className={active ? classNames(dot, dotActive) : dot}
      onClick={handleClick}
    ></Button>
  );
};

export default BannerSwipeable;
