import React from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Theme, useTheme, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  bannerImage: {
    objectFit: 'cover',
    width: '100%',
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  menuImage: {
    cursor: 'pointer',
    marginTop: 24,
    objectFit: 'cover',
    width: '100%',
  },
}));

const Landing: React.FC = () => {
  const { bannerImage, container, menuImage } = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down('xs'));
  return (
    <>
      <img className={bannerImage} src="images/landing/banner.png" />
      {xsDown ? (
        <Container maxWidth="lg">
          <img
            className={menuImage}
            src="images/landing/menu-1.png"
            alt=""
            onClick={() => history.push('/home-teenage')}
          />
          <img
            className={menuImage}
            src="images/landing/menu-2.png"
            alt=""
            onClick={() => history.push('/lgbt')}
          />
          <img
            className={menuImage}
            src="images/landing/menu-3.png"
            alt=""
            onClick={() => history.push('/home-general')}
          />
        </Container>
      ) : (
        <Container className={container} maxWidth="lg">
          <div>
            <img
              className={menuImage}
              src="images/landing/menu-teenage.png"
              alt=""
              onClick={() => history.push('/home-teenage')}
            />
          </div>
          <div>
            <img
              className={menuImage}
              src="images/landing/menu-lgbt.png"
              alt=""
              onClick={() => history.push('/lgbt')}
            />
          </div>
          <div>
            <img
              className={menuImage}
              src="images/landing/menu-general.png"
              alt=""
              onClick={() => history.push('/home-general')}
            />
          </div>
        </Container>
      )}
    </>
  );
};

export default Landing;
