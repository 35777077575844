import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  Divider,
  Grid,
  Hidden,
  Icon,
  IconButton,
  InputAdornment,
  Popover,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/styles';
import { Search, Subscriptions } from '@material-ui/icons';
import clsx from 'clsx';
import { fetchSessions } from 'common/services/stat';
import { getUrl } from 'common/services/search';
import { submitEmail } from 'common/services/subscription';

import Container from './Container';
import InfoDialog from './InfoDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(4),
    color: 'white',
  },
  divider: {
    margin: `${theme.spacing(2)} 0`,
  },
  socialIcon: {
    cursor: 'pointer',
    height: '48px',
    marginRight: theme.spacing(1),
    width: '48px',
  },
  statIcon: {
    cursor: 'pointer',
    marginLeft: theme.spacing(2),
  },
  statPaper: {
    transform: `translateY(${-theme.spacing(0.5)}px) !important`,
  },
}));

const mainMenus1 = [
  { text: 'เกี่ยวกับเรา', link: '/about-us' },
  { text: 'เอชไอวีและเอดส์', link: '/hiv' },
  { text: 'โรคติดต่อทางเพศสัมพันธ์', link: '/disease' },
  { text: 'วัณโรค ', link: '/tb' },
  { text: 'บริการตรวจ/รักษา', link: '/service' },
  { text: 'ถุงยางอนามัยและสารหล่อลื่น', link: '/condom' },
];

const mainMenus2 = [
  { text: 'PrEP/PEP', link: '/preppep' },
  { text: 'S&D', link: '/s-and-d' },
  { text: 'สุขภาพคนข้ามเพศ', link: '/transgender' },
  { text: 'สุขภาพจิต', link: '/mental-health' },
  { text: 'หน่วยบริการ', link: '/search' },
  { text: 'ประชาสัมพันธ์', link: '/public-relation' },
];

const Footer = ({ onIconClick }) => {
  const [stats, setStats] = useState([0, 0, 0]);
  const [showStat, setShowStat] = useState(false);
  const [search, setSearch] = useState('');
  const [email, setEmail] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const statRef = useRef();
  const { root, divider, socialIcon, statIcon, statPaper } = useStyles();
  const theme = useTheme();

  useEffect(() => {
    fetchSessions().then((results) => setStats(results));
  }, []);

  const handleSubmitDialog = async () => {
    await submitEmail(email);
    setEmail('');
    setOpenDialog(true);
  };

  return (
    <>
      <Container backgroundColor={theme.palette.primary.main}>
        <Grid className={root} container>
          <Grid item xs={12} md={3}>
            {mainMenus1.map((menu, index) => (
              <Link
                key={index}
                to={menu.link}
                style={{ textDecoration: 'none', color: 'white' }}
              >
                <Typography color="inherit">{menu.text}</Typography>
              </Link>
            ))}
            <Hidden mdUp>
              <Divider className={divider} />
            </Hidden>
          </Grid>
          <Grid item xs={12} md={3}>
            {mainMenus2.map((menu, index) => (
              <Link
                key={index}
                to={menu.link}
                style={{ textDecoration: 'none', color: 'white' }}
              >
                <Typography color="inherit">{menu.text}</Typography>
              </Link>
            ))}
            <Hidden mdUp>
              <Divider className={divider} />
            </Hidden>
          </Grid>
          <Grid item xs={12} md={6}>
            <img
              className={socialIcon}
              src="/images/btn-fb.png"
              alt=""
              onClick={() => onIconClick('facebook')}
            />
            <img
              className={socialIcon}
              src="/images/btn-youtube.png"
              alt=""
              onClick={() => onIconClick('youtube')}
            />
            <Box display="flex" alignItems="center">
              <TextField
                label="ช่องค้นหาภายในเว็บไซต์"
                margin="dense"
                variant="filled"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                InputProps={{
                  style: { backgroundColor: 'white' },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          window.open(getUrl(search));
                          setSearch('');
                        }}
                      >
                        <Search />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box display="flex" alignItems="center">
              <TextField
                label="สมัครรับจดหมายข่าว"
                margin="dense"
                variant="filled"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                InputProps={{
                  style: { backgroundColor: 'white' },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleSubmitDialog}>
                        <Subscriptions />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                helperText="กรุณาระบุ E-mail"
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Container backgroundColor={theme.palette.primary.dark}>
        <Box display="flex" color="common.white" px={4} py={1}>
          <Typography color="inherit">
            &copy; 2019. All right reserved
          </Typography>
          <Icon
            className={clsx('far fa-chart-bar', statIcon)}
            ref={statRef}
            aria-describedby="stat-popover"
            onClick={() => setShowStat(true)}
          />
          <Popover
            classes={{ paper: statPaper }}
            id="stat-popover"
            open={showStat}
            anchorEl={statRef.current}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            onClose={() => setShowStat(false)}
          >
            <Box display="flex" flexDirection="column" p={1}>
              <Typography variant="caption" style={{ fontWeight: 'bold' }}>
                ยอดผู้เข้าชม
              </Typography>
              <Typography variant="caption">
                ทั้งหมด {stats[0]} ครั้ง
              </Typography>
              <Typography variant="caption">วันนี้ {stats[1]} ครั้ง</Typography>
              <Typography variant="caption">
                เมื่อวานนี้ {stats[2]} ครั้ง
              </Typography>
            </Box>
          </Popover>
        </Box>
      </Container>
      <InfoDialog open={openDialog} onClose={() => setOpenDialog(false)} />
    </>
  );
};

export default Footer;
