import React from 'react';
import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  button: {
    margin: '0px 4px'
  },
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '16px'
  },
  innerRoot: {
    display: 'flex',
    alignItems: 'center'
  }
}));

const Paginator = ({
  currentPage,
  itemPerPage,
  totalItem,
  onPrevPage,
  onNextPage
}) => {
  const { button, innerRoot, root } = useStyles();
  // last item cannot exceed total item
  const lastItem =
    (currentPage + 1) * itemPerPage > totalItem
      ? totalItem
      : (currentPage + 1) * itemPerPage;
  return (
    <div className={root}>
      <div className={innerRoot}>
        <div>
          {currentPage * itemPerPage + 1} - {lastItem} จาก {totalItem}
        </div>
        <IconButton
          className={button}
          size="small"
          disabled={currentPage === 0}
          onClick={onPrevPage}
        >
          <ChevronLeft fontSize="small" />
        </IconButton>
        <IconButton
          className={button}
          size="small"
          disabled={lastItem === totalItem}
          onClick={onNextPage}
        >
          <ChevronRight fontSize="small" />
        </IconButton>
      </div>
    </div>
  );
};

export default Paginator;
