import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';

const EmptyLoading = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight={600}
    >
      <CircularProgress />
    </Box>
  );
};

export default EmptyLoading;
