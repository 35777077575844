import React from 'react';
import { Grid, IconButton, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { SaveAlt } from '@material-ui/icons';
import Paginator from './Paginator';

const useStyle = makeStyles(theme => ({
  file: {
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1)
  },
  fileIcon: {},
  fileInfo: {
    display: 'flex',
    minHeight: theme.spacing(12),
    padding: `${theme.spacing(1)}px 0px`
  },
  fileThumbnail: {
    alignSelf: 'center',
    height: '240px',
    objectFit: 'cover',
    objectPosition: 'center',
    width: '90%'
  },
  fileTitle: {
    padding: theme.spacing(1)
  }
}));

const FileList = ({
  items,
  currentPage,
  itemPerPage,
  onFileClick,
  onPrevPage,
  onNextPage
}) => {
  return (
    <div>
      <Grid container spacing={2}>
        {items
          .slice(currentPage * itemPerPage, (currentPage + 1) * itemPerPage)
          .map((item, index) => (
            <Grid key={index} item xs={12} sm={6} md={3}>
              <FileItem item={item} onClick={onFileClick} />
            </Grid>
          ))}
      </Grid>
      {items.length > itemPerPage && ( // paginator required only if total item doesn't fit in one page
        <Paginator
          currentPage={currentPage}
          itemPerPage={itemPerPage}
          totalItem={items.length}
          onPrevPage={onPrevPage}
          onNextPage={onNextPage}
        />
      )}
    </div>
  );
};

const FileItem = ({ item, onClick }) => {
  const { file, fileIcon, fileInfo, fileThumbnail, fileTitle } = useStyle({
    image: item.image
  });
  return (
    <Paper className={file}>
      <img className={fileThumbnail} src={item.image} alt="" />
      <div className={fileInfo}>
        <div className={fileIcon}>
          <IconButton onClick={() => onClick(item)}>
            <SaveAlt color="primary" />
          </IconButton>
        </div>
        <div className={fileTitle}>
          <Typography>{item.title}</Typography>
        </div>
      </div>
    </Paper>
  );
};

export default FileList;
