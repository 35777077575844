import React from 'react';
import { Box, Button, Typography, Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import {
  AccessTime,
  Directions,
  Map as MapIcon,
  Phone,
  DirectionsSubway
} from '@material-ui/icons';
import { compose, withProps } from 'recompose';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from 'react-google-maps';

const useStyles = makeStyles(theme => ({
  buttonIcon: {
    marginRight: '8px'
  },
  icon: {},
  map: {
    backgroundImage: ({ mapImage }) => `url(${mapImage})`,
    backgroundSize: 'cover',
    minHeight: 380,
    overflow: 'hidden',
    position: 'relative'
  },
  marker: {
    backgroundImage: ({ image }) => `url('${image}')`,
    cursor: 'pointer',
    height: '93px',
    left: ({ position }) =>
      position && (window.innerWidth / 1280) * position.left - 40,
    position: 'absolute',
    top: ({ position }) => position && position.top - 93,
    transform: `scale(${
      window.innerWidth / 1280 < 1 ? window.innerWidth / 1280 : 1
    })`,
    width: '80px'
  },
  responsiveImage: theme.responsiveImage,
  sideBar: {
    backgroundColor: 'white',
    height: '100%',
    overflowY: 'auto',
    position: 'absolute',
    top: '0px',
    width: '260px'
  },
  sideBarHeader: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    padding: '16px'
  },
  titleIcon: {
    height: '36px',
    marginRight: '16px',
    width: '36px'
  },
  chip: {
    margin: theme.spacing(1)
  }
}));

const formatEscapedText = (str, keyPrefix) =>
  str &&
  str
    .split('\\n')
    .map((str, index) => (
      <Typography key={`${keyPrefix}-${index}`}>{str}</Typography>
    ));

const apiKey = 'AIzaSyDg9kb5g2oveDzarV50grGQ6oXGd-Tpqtc';

const mapProps = {
  googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${apiKey}&v=3.exp&libraries=geometry,drawing,places`,
  loadingElement: <div style={{ height: `100%` }} />,
  containerElement: <div style={{ height: `100%` }} />,
  mapElement: <div style={{ height: `100%` }} />
};

const mapBuilder = compose(
  withProps(mapProps),
  withScriptjs,
  withGoogleMap
);

const MyGoogleMap = mapBuilder(
  ({
    defaultZoom,
    defaultCenter,
    icon,
    selectedIcon,
    markers,
    onMarkerClick,
    selected
  }) => {
    return (
      <GoogleMap
        defaultZoom={defaultZoom}
        defaultCenter={defaultCenter}
        onClick={() => onMarkerClick(null)}
      >
        {markers.map((data, index) => (
          <Marker
            key={index}
            position={{ lat: data.lat, lng: data.lng }}
            icon={
              selected && selected.title === data.title ? selectedIcon : icon
            }
            onClick={() => onMarkerClick(data)}
          />
        ))}
      </GoogleMap>
    );
  }
);

const Map = ({
  defaultZoom,
  defaultCenter,
  icon,
  markers,
  onMarkerClick,
  selected,
  selectedIcon,
  height,
  onClickDirection
}) => {
  return (
    <div style={{ height, position: 'relative' }}>
      <MyGoogleMap
        defaultZoom={defaultZoom}
        defaultCenter={defaultCenter}
        icon={icon}
        selectedIcon={selectedIcon}
        markers={markers}
        onMarkerClick={onMarkerClick}
        selected={selected}
        height={height}
      />
      {selected && (
        <SideBar info={selected} onClickDirection={onClickDirection} />
      )}
    </div>
  );
};

const SideBar = ({ info, onClickDirection }) => {
  const { buttonIcon, sideBar, sideBarHeader, chip } = useStyles();
  const { title, extra, hours, tel, address, train } = info;
  const getExtraText = str => {
    switch (str) {
      case 'y':
        return 'เยาวชน';
      case 'm':
        return 'ชายรักชาย';
      case 't':
        return 'คนข้ามเพศ';
      default:
        return '';
    }
  };
  return (
    <div className={sideBar}>
      <div className={sideBarHeader}>
        <Typography variant="h6" color="inherit">
          {title}
        </Typography>
      </div>
      <Box display="flex" p={1}>
        {extra &&
          extra !== '-' &&
          extra
            .split(',')
            .map((tag, index) => (
              <Chip
                key={index}
                size="small"
                className={chip}
                label={getExtraText(tag)}
              />
            ))}
      </Box>
      <Box px={1} py={2}>
        {train && train !== '-' && (
          <Box display="flex" p={1}>
            <Box mr={1}>
              <DirectionsSubway color="primary" />
            </Box>
            <Typography component="div">
              {formatEscapedText(train, 't')}
            </Typography>
          </Box>
        )}
        <Box display="flex" p={1}>
          <Box mr={1}>
            <AccessTime color="primary" />
          </Box>
          <Typography component="div">
            {formatEscapedText(hours, 'h')}
          </Typography>
        </Box>
        <Box display="flex" p={1}>
          <Box mr={1}>
            <Phone color="primary" />
          </Box>
          <Typography>{tel}</Typography>
        </Box>
        <Box display="flex" p={1}>
          <Box mr={1}>
            <MapIcon color="primary" />
          </Box>
          <Typography>{address}</Typography>
        </Box>
        <Box my={1} />
        <Box p={1}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => onClickDirection(info)}
          >
            <Directions className={buttonIcon} />
            เส้นทาง
          </Button>
        </Box>
      </Box>
    </div>
  );
};

export default Map;
