import React, { useState } from 'react';
import { animateScroll as scroll } from 'react-scroll';
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Hidden,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/styles';
import CampaignCover from '../components/CampaignCover';
import { submitCampaign1 } from 'common/services/feedback';

const useStyles = makeStyles(theme => ({
  header: {
    // background: `url('/images/campaign/campaign1-header-bg.png')`,
    // backgroundPosition: 'center',
    // backgroundSize: 'cover',
    // color: theme.palette.common.white
    width: '100%'
  },
  outroBackground: {
    alignItems: 'center',
    backgroundImage: `url('images/campaign/campaign1-outro-bg.png')`,
    backgroundSize: 'cover',
    backgroundPosition: 'bottom',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      height: `calc(100vh - 60px)`
    }
  },
  outroForeground: {
    bottom: 0,
    position: 'absolute',
    maxWidth: '60%'
  },
  outroText: {
    fontSize: '21px'
  },
  radioGroupRow: {
    display: 'flex',
    justifyContent: 'space-around'
  },
  submitButton: {
    padding: `${theme.spacing(1)}px ${theme.spacing(8)}px`
  }
}));

const personalities = [
  'หวานใส',
  'เปรี้ยว',
  'แรด',
  'ห้าว',
  'กึ่งๆ ลังเลว่าชอบหญิงด้วยกัน',
  'แสดงออกชัดเจนว่าชอบหญิงด้วยกัน',
  'แปลงเพศเป็นหญิง',
  'แปลงเพศเป็นชาย',
  'แสดงออกชัดเจนว่าชอบชายด้วยกัน',
  'กึ่งๆ ลังเลว่าชอบชายด้วยกัน',
  'สุภาพ เรียบร้อย อ่อนหวาน',
  'แมนๆ',
  'นิ่งๆ สุขุม',
  'เข้มแข็ง'
];

const ages = [
  'น้อยกว่า 12 ปี',
  '12 - 16 ปี',
  '17 - 21 ปี',
  '22 - 26 ปี',
  '27 - 32 ปี',
  '33 - 37 ปี',
  'มากกว่า 37 ปี'
];

const Campaign1 = () => {
  const [entered, setEntered] = useState(false);
  const [risky, setRisky] = useState();
  const [finished, setFinished] = useState(false);
  const { submitButton } = useStyles();

  if (!entered) return <CampaignCover onEnter={() => setEntered(true)} />;
  else if (!finished)
    return (
      <>
        <Evaluation onSubmit={setRisky} />
        <Dialog open={risky !== undefined}>
          <DialogContent>
            <DialogTitle>ผลการประเมิน</DialogTitle>
            <DialogContentText>
              {risky ? (
                <>
                  คุณ{' '}
                  <Box component="span" color="primary.main">
                    <strong>มีโอกาสเสี่ยงต่อการรับเชื้อเอชไอวี</strong>
                  </Box>{' '}
                  แต่คุณอาจไม่ทันคิดว่าสิ่งที่ตัวเองทำมีความเสี่ยง
                  เมื่อรู้อย่างนี้แล้ว คุณก็ควรรู้จักป้องกันทั้งตนเองและคู่รัก
                  หากสงสัยว่าที่ผ่านมาเราได้รับเชื้อเอชไอวีแล้วหรือไม่{' '}
                  <Box component="span" color="primary.main">
                    การเข้ารับการตรวจเลือดเป็นทางเดียวที่จะรู้ได้
                    การเข้ารับการตรวจเลือดเป็นทางเดียวที่จะรู้ได้
                  </Box>
                </>
              ) : (
                <>
                  คุณ{' '}
                  <Box component="span" color="primary.main">
                    <strong>
                      <em>ไม่</em> มีโอกาสเสี่ยงต่อการรับเชื้อเอชไอวี
                    </strong>
                  </Box>{' '}
                  แต่ถึงอย่างนั้น คุณก็ควรรู้จักวิธีการป้องกัน
                  เพื่อให้คุณและคู่มีรักปลอดภัย{' '}
                  <Box component="span" color="primary.main">
                    Safe love
                  </Box>{' '}
                  กันไปอย่างยาวนาน
                </>
              )}
            </DialogContentText>
            <Box display="flex" justifyContent="center" mt={4} mb={2}>
              <Button
                className={submitButton}
                variant="contained"
                size="large"
                color="primary"
                onClick={() => {
                  scroll.scrollToTop();
                  setFinished(true);
                }}
              >
                ไปต่อ
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
      </>
    );
  return <Outro />;
};

const RadioChoice = ({ label, name, choice1, choice2, onChange }) => {
  const { radioGroupRow } = useStyles();
  return (
    <Box component={Paper} px={4} py={2} mb={2}>
      <Typography variant="h6" color="primary">
        {label}
      </Typography>
      <RadioGroup row className={radioGroupRow} name={name} onChange={onChange}>
        <FormControlLabel
          {...choice1}
          control={<Radio color="primary" />}
          labelPlacement="bottom"
        />
        <FormControlLabel
          {...choice2}
          control={<Radio color="primary" />}
          labelPlacement="bottom"
        />
      </RadioGroup>
    </Box>
  );
};

const Evaluation = ({ onSubmit }) => {
  const [result, setResult] = useState({
    personality: 'หวานใส',
    age: 'น้อยกว่า 12 ปี'
  });
  const { header, submitButton } = useStyles();
  const handleChange = e => {
    setResult({ ...result, [e.target.name]: e.target.value });
    console.log(e.target.name, e.target.value);
  };
  const handleSubmit = e => {
    console.log(result);
    const risky = Object.values(result).includes('yes');
    submitCampaign1(result);
    onSubmit(risky);
  };
  return (
    <Box minHeight="600px">
      <Box mb={2}>
        <img
          className={header}
          src="/images/campaign/campaign1-header-bg.png"
          alt="header"
        />
        {/* <Typography variant="h5" align="center" style={{ lineHeight: '2rem' }}>
          แบบสำรวจพฤติกรรมและประเมินความเสี่ยงต่อการติดเชื้อเอชไอวี
        </Typography> */}
      </Box>
      <Container maxWidth="md">
        <RadioChoice
          label="เพศกำเนิด"
          name="gender"
          choice1={{ value: 'male', label: 'ชาย' }}
          choice2={{ value: 'female', label: 'หญิง' }}
          onChange={handleChange}
        />
        <Box component={Paper} px={4} py={2} mb={2}>
          <Typography variant="h6" color="primary">
            ฉันเป็นแบบไหน
          </Typography>
          <Box display="flex" justifyContent="center" p={2}>
            <TextField
              select
              style={{ width: 300 }}
              name="personality"
              variant="outlined"
              value={result.personality}
              onChange={handleChange}
            >
              {personalities.map(personality => (
                <MenuItem key={personality} value={personality}>
                  {personality}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        </Box>
        <Box component={Paper} px={4} py={2} mb={2}>
          <Typography variant="h6" color="primary">
            ฉันอายุเท่าไร
          </Typography>
          <Box display="flex" justifyContent="center" p={2}>
            <TextField
              select
              style={{ width: 300 }}
              name="age"
              variant="outlined"
              value={result.age}
              onChange={handleChange}
            >
              {ages.map(age => (
                <MenuItem key={age} value={age}>
                  {age}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        </Box>
        <RadioChoice
          label="ฉัน เคย มีเซ็กส์โดยไม่ใช้ถุงยางอนามัย"
          name="noCondom"
          choice1={{ value: 'yes', label: 'ใช่' }}
          choice2={{ value: 'no', label: 'ไม่ใช่' }}
          onChange={handleChange}
        />
        <RadioChoice
          label="ฉัน เคย ดื่มแอลกอฮอล์หรือใช้สารเสพติดก่อนมีเซ็กส์"
          name="alcohol"
          choice1={{ value: 'yes', label: 'ใช่' }}
          choice2={{ value: 'no', label: 'ไม่ใช่' }}
          onChange={handleChange}
        />
        <RadioChoice
          label="ฉัน มีคู่นอนมากกว่า 1 คน"
          name="onenight"
          choice1={{ value: 'yes', label: 'ใช่' }}
          choice2={{ value: 'no', label: 'ไม่ใช่' }}
          onChange={handleChange}
        />
        <RadioChoice
          label="ฉัน มีเซ็กส์กับผู้ชายด้วยกัน (ชายรักชาย)"
          name="homo"
          choice1={{ value: 'yes', label: 'ใช่' }}
          choice2={{ value: 'no', label: 'ไม่ใช่' }}
          onChange={handleChange}
        />
        <RadioChoice
          label="ฉัน เคย ใช้เข็มฉีดสารเสพติดและ/หรือ กระบอกฉีดยาร่วมกับผู้อื่น"
          name="injection"
          choice1={{ value: 'yes', label: 'ใช่' }}
          choice2={{ value: 'no', label: 'ไม่ใช่' }}
          onChange={handleChange}
        />
        <RadioChoice
          label="ฉัน เคย เป็นโรคติดต่อทางเพศสัมพันธ์ เช่น หนองใน ซิฟิลิส เริม ฯลฯ"
          name="disease"
          choice1={{ value: 'yes', label: 'ใช่' }}
          choice2={{ value: 'no', label: 'ไม่ใช่' }}
          onChange={handleChange}
        />
        <RadioChoice
          label="ฉัน เคย ตรวจเลือดเพื่อหาการติดเชื้อเอชไอวี"
          name="bloodtest"
          choice1={{ value: 'yes', label: 'ใช่' }}
          choice2={{ value: 'no', label: 'ไม่ใช่' }}
          onChange={handleChange}
        />
        <Box display="flex" justifyContent="center" m={4}>
          <Button
            className={submitButton}
            variant="contained"
            color="primary"
            size="large"
            disabled={Object.keys(result).length < 10}
            onClick={handleSubmit}
          >
            ตรวจสอบผล
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

const Outro = () => {
  const theme = useTheme();
  const { outroBackground, outroForeground, outroText } = useStyles();
  return (
    <Box className={outroBackground}>
      <Container maxWidth="md">
        <Box mt={2} mb={1} color="common.white">
          <Typography variant="body2" align="right">
            ( capture หน้าจอนี้สำหรับการเข้าร่วมกิจกรรม )
          </Typography>
        </Box>
        <Box
          component={Paper}
          display="flex"
          justifyContent="center"
          mb={2}
          px={2}
          py={4}
        >
          <Box>
            <Typography variant="h3" align="center">
              ตรวจเลือดหาการติดเชื้อเอชไอวี
            </Typography>
            <Box mb={2} />
            <Typography variant="h1" align="center" color="primary">
              ฟรี!! ปีละ 2 ครั้ง
            </Typography>
            <Box mb={2} />
            <Typography align="center">(เพียงมีบัตรประจำตัวประชาชน)</Typography>
            <Box mb={6} />
            <Typography className={outroText} align="center">
              ที่ศูนย์บริการสาธารณสุข 68 แห่ง ในกรุงเทพมหานคร
              <br />
              สถานพยาบาลทุกแห่งที่อยู่ในระบบหลักประกันสุขภาพแห่งชาติ
              <br />
              ประกันสังคม และสวัสดิการข้าราชการ
            </Typography>
            <Box mb={6} />
            <Typography className={outroText} align="center">
              สายด่วนปรึกษาเอดส์ เพศ ท้องไม่พร้อม{' '}
              <span
                style={{
                  color: theme.palette.primary.main,
                  fontSize: '24px',
                  fontWeight: 500
                }}
              >
                1663
              </span>
              <br />
              เปิดให้บริการทุกวัน 09.00 - 21.00 น. (โทรฟรี)
            </Typography>
            <Box mb={4} />
          </Box>
        </Box>
        <Hidden mdDown>
          <img
            className={outroForeground}
            src="/images/campaign/campaign1-outro-fg.png"
            alt="campaign1"
          />
        </Hidden>
      </Container>
    </Box>
  );
};

export default Campaign1;
