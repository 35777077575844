import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const InfoDialog = ({ open, onClose }) => {
  return (
    <Dialog fullWidth={true} maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle id="dialog-title">เราได้รับข้อมูลของคุณแล้ว</DialogTitle>
      <DialogContent>
        <DialogContentText>
          คุณได้สมัครรับข่าวจากเราเรียบร้อยแล้ว ขอบคุณค่ะ
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          ตกลง
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InfoDialog;
