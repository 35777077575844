import React, { useState } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundBlendMode: 'multiply',
    backgroundColor: ({ hover }) => hover && '#666',
    backgroundImage: ({ background }) => `url("${background}")`,
    backgroundSize: 'cover',
    borderRadius: '8px',
    color: 'white',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    height: '314px',
    padding: '16px',
    '&$disabled': {
      color: 'black'
    }
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: '1'
  },
  icon: {
    alignSelf: 'flex-start',
    marginRight: '8px'
  },
  title: {
    display: 'flex',
    marginBottom: '16px'
  },
  section: theme.section
}));

const Hilights = ({ items, onItemClick }) => {
  const { section } = useStyles();
  return (
    <Box p={0.5}>
      <Grid className={section} container spacing={1}>
        <Grid item xs={12} sm={4}>
          <Hilight {...items[0]} onClick={onItemClick} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Hilight {...items[1]} onClick={onItemClick} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Hilight {...items[2]} onClick={onItemClick} />
        </Grid>
      </Grid>
    </Box>
  );
};

export const Hilight = ({ background, content, icon, title, url, onClick }) => {
  const [hover, setHover] = useState(false);
  const {
    root,
    content: contentStyle,
    icon: iconStyle,
    title: titleStyle
  } = useStyles({ background, hover });

  return (
    <div
      className={root}
      onClick={() => onClick(url)}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div className={titleStyle}>
        <img className={iconStyle} src={`${icon}`} alt="" />
        <Typography variant="h4" color="inherit">
          {title}
        </Typography>
      </div>
      <div className={contentStyle}>
        <Typography variant="subtitle1" color="inherit" paragraph>
          {content}
        </Typography>
      </div>
      <Typography color="inherit">อ่านเพิ่มเติม</Typography>
    </div>
  );
};

export default Hilights;
