import React, { useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  AppBar,
  Box,
  Collapse,
  Container,
  Drawer,
  Grow,
  Hidden,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Paper,
  Popper,
  Theme,
  Toolbar,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Menu as MenuIcon, ExpandLess, ExpandMore } from '@material-ui/icons';
import { menuMap, headerMenus } from 'common/menus';

interface Props {
  hideMenu?: boolean;
}

interface SideBarProps {
  open: boolean;
  onClose: () => void;
}

const drawerWidth = 240;
const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    backgroundColor: 'white',
    [theme.breakpoints.up('lg')]: {
      background: `linear-gradient(90deg, #FFF 50%, ${theme.palette.primary.main} 50%)`
    }
  },
  drawerPaper: {
    width: drawerWidth
  },
  listItemDefault: {
    textDecoration: 'none',
    color: theme.palette.common.black
  },
  listItemSelected: {
    textDecoration: 'none',
    color: theme.palette.primary.main
  },
  logo: {
    cursor: 'pointer',
    textDecoration: 'none'
  },
  menuIcon: {
    alignSelf: 'center',
    cursor: 'pointer',
    justifySelf: 'end',
    marginRight: '8px'
  },
  redPaper: {
    backgroundColor: theme.palette.primary.main
  }
}));

const Header: React.FC<Props> = ({ hideMenu = false }) => {
  const [open, setOpen] = useState(false);
  const { appBar, logo, menuIcon } = useStyles();

  return (
    <>
      <AppBar position="static" className={appBar}>
        <Container maxWidth="lg">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={1}
            height={60}
          >
            <Link to="/" className={logo}>
              <img
                style={{ height: '44px' }}
                src="/images/logo.png"
                alt="logo"
              />
            </Link>
            <Hidden mdDown>
              <img src="/images/head.png" style={{ height: '60px' }} alt="" />
              <MenuBar hideMenu={hideMenu} />
            </Hidden>
            <Hidden lgUp>
              {!hideMenu && (
                <MenuIcon
                  className={menuIcon}
                  color="primary"
                  onClick={() => setOpen(!open)}
                />
              )}
            </Hidden>
          </Box>
        </Container>
      </AppBar>
      <Hidden lgUp>
        <SideBar open={open} onClose={() => setOpen(false)} />
      </Hidden>
    </>
  );
};

const MenuBar: React.FC<{ hideMenu: boolean }> = ({ hideMenu }) => {
  const location = useLocation();
  const menus = hideMenu ? [] : menuMap;
  return (
    <Box
      alignItems="center"
      bgcolor="primary.main"
      display="flex"
      flexGrow={1}
      fontSize={11}
      justifyContent="space-between"
      p={1}
      height={60}
    >
      {!hideMenu && <MenuBarItem location={location} />}
      {/* {menus
        .filter((menu: MenuData) => menu.link !== '/' && menu.menu)
        .map((menu: MenuData, index: number) => (
          <Link
            to={menu.link}
            key={index}
            style={{
              color: 'white',
              textDecoration:
                location.pathname === menu.link ? 'underline' : 'none'
            }}
          >
            <Typography
              variant="body2"
              style={{ fontSize: '0.825rem' }}
              key={index}
              noWrap
            >
              {menu.text}
            </Typography>
          </Link>
        ))} */}
    </Box>
  );
};

const MenuBarItem: React.FC<{ location: any }> = ({ location }) => {
  return (
    <>
      {headerMenus.map((menu: HeaderMenu, index: number) => {
        if (menu.submenus.length > 1) {
          return <SubmenuBarList key={index} menu={menu} location={location} />;
        } else {
          const submenu = menu.submenus[0];
          return (
            <Link
              to={submenu.link}
              key={index}
              style={{
                color: 'white',
                textDecoration:
                  location.pathname === submenu.link ? 'underline' : 'none'
              }}
            >
              <Typography
                variant="body2"
                style={{ fontSize: '0.825rem' }}
                key={index}
                noWrap
              >
                {submenu.text}
              </Typography>
            </Link>
          );
        }
      })}
    </>
  );
};

const SubmenuBarList: React.FC<{ menu: HeaderMenu; location: any }> = ({
  menu,
  location
}) => {
  const [hover, setHover] = useState(false);
  const anchorRef = useRef(null);

  const { redPaper } = useStyles();

  const handleHover = (e: any) => {
    console.log('onMouseEnter');
    setHover(true);
  };
  const handleUnhover = () => {
    console.log('onMouseLeave');
    setHover(false);
  };

  return (
    <>
      <Typography
        ref={anchorRef}
        variant="body2"
        style={{ fontSize: '0.825rem', cursor: 'pointer' }}
        noWrap
        onMouseEnter={handleHover}
        onMouseLeave={handleUnhover}
      >
        {menu.text}
      </Typography>
      <Popper
        open={hover}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        onMouseEnter={handleHover}
        onMouseLeave={handleUnhover}
        style={{ zIndex: 500 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: 'center bottom'
            }}
          >
            <Paper className={redPaper}>
              {menu.submenus.map((submenu, index) => (
                <MenuItem key={index}>
                  <Link
                    to={submenu.link}
                    key={index}
                    style={{
                      color: 'white',
                      textDecoration:
                        location.pathname === submenu.link
                          ? 'underline'
                          : 'none'
                    }}
                  >
                    <Typography
                      variant="body2"
                      style={{ fontSize: '0.825rem' }}
                      key={index}
                      noWrap
                    >
                      {submenu.text}
                    </Typography>
                  </Link>
                </MenuItem>
              ))}
            </Paper>
          </Grow>
        )}
      </Popper>
      {/* <Menu open={hover} anchorEl={anchorRef.current}>
        {menu.submenus.map((submenu, index) => (
          <MenuItem key={index}>
            <Link
              to={submenu.link}
              key={index}
              style={{
                color: 'white',
                textDecoration:
                  location.pathname === submenu.link ? 'underline' : 'none'
              }}
            >
              <Typography
                variant="body2"
                style={{ fontSize: '0.825rem' }}
                key={index}
                noWrap
              >
                {submenu.text}
              </Typography>
            </Link>
          </MenuItem>
        ))}
      </Menu> */}
    </>
  );
};

const SideBar: React.FC<SideBarProps> = ({ open, onClose }) => {
  const location = useLocation();
  const { drawerPaper, listItemDefault, listItemSelected } = useStyles();
  return (
    <Drawer
      classes={{ paper: drawerPaper }}
      variant="temporary"
      open={open}
      onClose={onClose}
    >
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" color="inherit">
            รายการ
          </Typography>
        </Toolbar>
      </AppBar>
      <List>
        <SideBarItem location={location} onClose={onClose} />
      </List>
    </Drawer>
  );
};

const SideBarItem: React.FC<{ location: any; onClose: any }> = ({
  location,
  onClose
}) => {
  const { listItemDefault, listItemSelected } = useStyles();
  return (
    <>
      {headerMenus.map((menu: HeaderMenu, index: number) => {
        if (menu.submenus.length > 1) {
          return (
            <SiddeBarSubmenu
              key={index}
              menu={menu}
              location={location}
              onClose={onClose}
            />
          );
        } else {
          const submenu = menu.submenus[0];
          return (
            <Link
              key={index}
              to={submenu.link}
              className={
                location.pathname === submenu.link
                  ? listItemSelected
                  : listItemDefault
              }
              onClick={onClose}
            >
              <ListItem>
                <ListItemText>{submenu.text}</ListItemText>
              </ListItem>
            </Link>
          );
        }
      })}
    </>
  );
};

const SiddeBarSubmenu: React.FC<{
  menu: HeaderMenu;
  location: any;
  onClose: any;
}> = ({ menu, location, onClose }) => {
  const [open, setOpen] = useState(false);
  const { listItemDefault, listItemSelected } = useStyles();
  return (
    <>
      <Box style={{ cursor: 'pointer' }} onClick={() => setOpen(!open)}>
        <ListItem>
          <ListItemText>{menu.text}</ListItemText>
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      </Box>
      <Collapse in={open} timeout="auto">
        <List>
          {menu.submenus.map((menu: HeaderSubmenu, index: number) => (
            <Link
              key={index}
              to={menu.link}
              className={
                location.pathname === menu.link
                  ? listItemSelected
                  : listItemDefault
              }
              onClick={onClose}
            >
              <ListItem>
                <ListItemText>{menu.text}</ListItemText>
              </ListItem>
            </Link>
          ))}
        </List>
      </Collapse>
    </>
  );
};

export default Header;
