import { database, fieldValue } from '../firebase';
import axios from 'axios';
import moment from 'moment';

const basePath =
  'https://us-central1-progaming-bkkhive.cloudfunctions.net/stat';

const getDateQuery = range => {
  let startDate = '';
  let endDate = '';

  range = range || 'alltime';
  const dateFormat = 'YYYY-MM-DD';

  switch (range) {
    case 'alltime':
      startDate = '2019-07-01';
      endDate = 'today';
      break;
    case 'today':
      startDate = 'today';
      endDate = 'today';
      break;
    case 'yesterday':
      startDate = 'yesterday';
      endDate = 'yesterday';
      break;
    case 'thismonth':
      startDate = moment()
        .startOf('month')
        .format(dateFormat);
      endDate = 'today';
      break;
    case 'lastmonth':
      startDate = moment()
        .subtract(1, 'month')
        .startOf('month')
        .format(dateFormat);
      endDate = moment()
        .subtract(1, 'month')
        .endOf('month')
        .format(dateFormat);
      break;
    case 'thisweek':
      startDate = moment()
        .startOf('week')
        .format(dateFormat);
      endDate = 'today';
      break;
    case 'lastweek':
      startDate = moment()
        .subtract(1, 'week')
        .startOf('week')
        .format(dateFormat);
      endDate = moment()
        .subtract(1, 'week')
        .endOf('week')
        .format(dateFormat);
      break;
  }
  return `startDate=${startDate}&endDate=${endDate}`;
};

export const fetchSessions = async () => {
  const promises = [
    await axios.get(`${basePath}/session?${getDateQuery('alltime')}`),
    await axios.get(`${basePath}/session?${getDateQuery('today')}`),
    await axios.get(`${basePath}/session?${getDateQuery('yesterday')}`)
  ];
  const resolves = await Promise.all(promises);
  return resolves.map(resolve => resolve.data.session);
};

export const fetchSession = async range => {
  const result = await axios.get(`${basePath}/session?${getDateQuery(range)}`);
  return result.data;
}

export const fetchView = async range => {
  const results = await axios.get(`${basePath}/view?${getDateQuery(range)}`);
  return results.data;
};

export const addLog = (info, path) => {
  const { appVersion, language } = info;
  const data = {
    appVersion,
    language,
    path,
    createdAt: fieldValue.serverTimestamp()
  };
  if (!path) delete data.path;

  database.collection('logs').add(data);
};
