import React, { useState } from 'react';
import { Button, Grid, Snackbar, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Title from './Title';

const useStyles = makeStyles(theme => ({
  description: {
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(1)
  },
  formContainer: {
    padding: theme.spacing(1)
  },
  innerRoot: {
    backgroundColor: 'white',
    padding: theme.spacing(2)
  },
  root: {}
}));

const ContactForm = ({ onSubmit }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [feedback, setFeedback] = useState('');
  const { description, formContainer, innerRoot, root } = useStyles();

  const handleSubmit = () => {
    onSubmit({ name, email, message });
    setName('');
    setEmail('');
    setMessage('');
    setFeedback('ส่งข้อความสำเร็จ ขอบคุณค่ะ 🥰');
  };

  return (
    <>
    <Grid container className={root} justify="center">
      <Grid item className={innerRoot} xs={12} md={8}>
        <Title text="ติดต่อเรา" icon={'comment_outline'} />
        <Typography className={description}>
          เรายินดีตอบทุกข้อสงสัย กรอกข้อมูลให้ครบถ้วนเพื่อส่งข้อความของคุณถึงเรา
        </Typography>
        <Grid container className={formContainer} spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              variant="outlined"
              label="ชื่อของคุณ"
              fullWidth
              value={name}
              onChange={e => setName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              variant="outlined"
              label="อีเมล"
              fullWidth
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              label="ข้อความของคุณ"
              fullWidth
              multiline
              rows={4}
              value={message}
              onChange={e => setMessage(e.target.value)}
            />
          </Grid>
          <Grid
            item
            xs={12}
            style={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={handleSubmit}
            >
              ส่งข้อความ
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    <Snackbar 
      open={feedback}
      autoHideDuration={3000}
      message={feedback}
      onClose={() => setFeedback('')}
    />
    </>
  );
};

export default ContactForm;
