import { database, fieldValue } from '../firebase';

//old feedback
export const submitPersonalInfo = (info) => {
  database.collection('feedback').add({
    ...info,
    source: 'personal',
    createdAt: fieldValue.serverTimestamp()
  });
};

export const submitQuestionair = (info) => {
  database.collection('questionairs').add({
    ...info,
    createdAt: fieldValue.serverTimestamp()
  });
};

export const sendMail = async (data) => {
  const response = await fetch(
    'https://us-central1-progaming-bkkhive.cloudfunctions.net/mail/send',
    {
      method: 'post',
      body: JSON.stringify(data),
      headers: { 'Content-Type': 'application/json' }
    }
  );

  return console.log(
    `email sent ${response.status === 200 ? 'successfully' : 'failed'}`
  );
};

export const submitCampaign1 = (data) => {
  database.collection('logs').add({
    campaign: 'campaign1',
    data,
    createdAt: fieldValue.serverTimestamp()
  });
};
