import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Icon from './Icon';

const useStyles = makeStyles(theme => ({
  topicColor: {
    color: '#19315B'
  },
  root: {
    alignItems: 'center',
    display: 'flex',
    paddingTop: theme.spacing(3)
  }
}));

const Title = ({ icon, text }) => {
  const { root, topicColor } = useStyles();
  return (
    <div className={root}>
      {icon && <Icon icon={icon} />}
      {text && (
        <Typography variant="h5" className={topicColor}>
          {text}
        </Typography>
      )}
    </div>
  );
};

export default Title;
