import React from 'react';
import { Box, Modal, Paper } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%'
  }
});

const VideoModal = ({ videoUrl, autoplay, open, onClose }) => {
  const { modal } = useStyles();
  const theme = useTheme();
  const modalWidth = 720 + theme.spacing(4);
  const ratio =
    window.innerWidth > modalWidth ? 1 : window.innerWidth / modalWidth;
  return (
    <Modal
      className={modal}
      open={open}
      onClose={onClose}
      disableAutoFocus={true}
    >
      <Box component={Paper} p={1}>
        <iframe
          title="post"
          width={720 * ratio}
          height={405 * ratio}
          src={`${videoUrl}${autoplay && '?autoplay=1'}`}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </Box>
    </Modal>
  );
};
export default VideoModal;
