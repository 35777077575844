import React, { useState } from 'react';
import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
  Snackbar,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import axios from 'axios';
import Preview from '../components/campaign2/Preview';
import Share from '../components/campaign2/Share';

const setting = {
  image: 'images/campaign/template3.png',
  lines: [{ x: 120, y: 140, width: 600, fontFamily: 'Kanit', fontSize: 40 }],
};

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    margin: `${theme.spacing(2)}px auto`,
  },
  contentGrid: {
    padding: '0px 16px',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: '16px',
    },
  },
  instruction: {
    backgroundColor: '#FFF',
    border: 'solid 1px #CCC',
    borderRadius: '4px',
    margin: '8px 0px',
    padding: '12px 16px',
    color: theme.palette.primary.main,
  },
  previewGrid: {
    width: '100%',
  },
  textField: {
    margin: '8px 0px',
    textAlign: 'center',
  },
}));

const Home = () => {
  const [message, setMessage] = useState('');
  const [shareUrl, setShareUrl] = useState();
  const [loading, setLoading] = useState(false);
  const [snackBar, setSnackBar] = useState();
  const {
    container,
    contentGrid,
    instruction,
    previewGrid,
    textField,
  } = useStyles();

  const handleChange = (e) => {
    setMessage(e.target.value);
  };
  const handleClick = async () => {
    setLoading(true);
    setSnackBar('กรุณารอสักครู่ กำลังสร้างรูป hashtag');
    const data = JSON.stringify({ message });
    try {
      const response = await axios.post(
        'https://us-central1-progaming-bkkhive.cloudfunctions.net/card',
        // 'http://localhost:5000/progaming-bkkhive/us-central1/card',
        data,
        { headers: { 'Content-Type': 'application/json' } }
      );
      const storagePath =
        'https://storage.googleapis.com/progaming-bkkhive.appspot.com';
      const fullPath = `${storagePath}/${response.data}`;
      setShareUrl(fullPath);
      setSnackBar();
      setLoading(false);
    } catch (e) {
      setSnackBar('มีข้อผิดพลาด กรุณาลองใหม่อีกครั้ง');
      setLoading(false);
    }
  };
  const handleReset = () => {
    setMessage('');
    setShareUrl();
  };

  const renderEdit = () => {
    return (
      <>
        <Box>
          <TextField
            id="message"
            className={textField}
            variant="outlined"
            fullWidth
            label="พิมพ์ #hashtag ที่นี่"
            helperText="ความยาวไม่เกิน 20 ตัวอักษร"
            inputProps={{
              maxLength: 20,
            }}
            disabled={loading}
            onChange={handleChange}
          />
        </Box>
        <Button
          variant="contained"
          color="primary"
          size="large"
          fullWidth
          disabled={loading}
          onClick={handleClick}
        >
          สร้างรูป
        </Button>
        <div className={instruction}>
          <Typography variant="h5">กิจกรรม : #hashtag นี้มันใช่เลย</Typography>
          <br />
          <Typography>
            สร้างรูป #hastag เท่ๆ ให้กับถุงยางอนามัย แล้วอย่าลืมนำรูปไป comment
            ใต้โพสกิจกรรมนะ
          </Typography>
        </div>
      </>
    );
  };

  return (
    <Container maxWidth="md" component={Paper} className={container}>
      <Grid container>
        <Grid item className={previewGrid} md={6}>
          <Preview setting={setting} messages={[message]} />
        </Grid>
        <Grid item className={contentGrid} md={6}>
          {shareUrl ? (
            <Share url={shareUrl} onReset={handleReset} />
          ) : (
            renderEdit()
          )}
        </Grid>
      </Grid>
      <Snackbar message={snackBar} open={snackBar} autoHideDuration={3000} />
    </Container>
  );
};

export default Home;
