import { database, api, fieldValue } from '../firebase';
import axios from 'axios';
import download from 'downloadjs';

export const submitEmail = async (email) => {
  await database.collection('subscriptions').doc(email).set({
    email,
    updatedAt: fieldValue.serverTimestamp()
  });
};

export const exportData = async () => {
  try {
    const res = await axios({
      method: 'get',
      url: `${api}/subscriptions/export`,
      headers: {
        'access-control-allow-origin': '*'
      },
      responseType: 'blob'
    });
    download(res.data, `subscriptions.xlsx`, res.headers['content-type']);
    console.log('res:', res.data);
  } catch (error) {
    throw error;
  }
};
