import React from 'react';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
  clickable: { cursor: 'pointer' },
  responsiveImage: theme.responsiveImage,
  section: theme.section
}));

const Banner = ({ image, section, link, onItemClick }) => {
  const { clickable, responsiveImage, section: sectionStyle } = useStyles({ link });
  return (
    <img
      className={classNames(responsiveImage, { [clickable]: link, [sectionStyle]: section })}
      src={image}
      alt=""
      onClick={() => link && link !== '' && onItemClick(link)}
    />
  );
};

export default Banner;
