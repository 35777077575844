import React, { useState } from 'react';
import { Box, Divider, Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Phone } from '@material-ui/icons';
import classNames from 'classnames';
import { chatItems } from '../configs/hotmenus-teenage';

const useStyles = makeStyles((theme) => ({
  colorBox: {
    alignItems: 'center',
    backgroundColor: ({ color }) => `${color}`,
    color: 'white',
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    justifyContent: 'space-evenly',
    padding: '16px',
  },
  colorBoxBtn: {
    cursor: 'pointer',
    padding: '10px',
    transform: ({ hover }) => `translateY(${hover ? '-4px' : '0px'})`,
  },
  colorBoxContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  item: {
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: '4px',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
    padding: '16px 5px',
    transform: ({ hover }) => `translateY(${hover ? '-4px' : '0px'})`,
  },
  paperLeft: {
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    display: 'flex',
    padding: '10px 30px',
    width: '100%',
  },
  root: {
    minHeight: '130px',
  },
  section: theme.section,
}));

const HotMenusTeenage = ({ items, onItemClick }) => {
  const { colorBox, section, root, paperLeft } = useStyles();
  return (
    <>
      <Box p={0.5}>
        <Grid className={classNames(section, root)} container spacing={1}>
          {items.map((item, index) => (
            <Grid key={index} item xs>
              <HotMenuTeenage {...item} onClick={onItemClick} />
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box p={0.5}>
        <Grid className={classNames(section, root)} container spacing={1}>
          <Grid item xs={12} md={4}>
            <LoveCare {...chatItems[0]} onClick={onItemClick} />
          </Grid>
          <Grid item xs={12} md={8}>
            <GuanTeen {...chatItems[1]} onClick={onItemClick} />
          </Grid>
        </Grid>
      </Box>
      <Box
        component={Paper}
        display="flex"
        flexDirection={{ xs: 'column', sm: 'row' }}
        width="100%"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box className={paperLeft} mb={{ xs: 1, sm: 0 }}>
          <Phone style={{ paddingRight: 8 }} />
          <Typography variant="h6">สายด่วน</Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          px={2}
          mb={{ xs: 1, sm: 0 }}
          onClick={() => window.open('tel:1663')}
          style={{ cursor: 'pointer' }}
        >
          <Typography style={{ paddingRight: 16 }} color="primary">
            1663
          </Typography>
          <Typography color="primary">
            สายด่วนปรึกษาเอดส์ และท้องไม่พร้อม
          </Typography>
        </Box>
        <Divider orientation="vertical" flexItem />
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          px={2}
          mb={{ xs: 1, sm: 0 }}
          onClick={() => window.open('tel:1323')}
          style={{ cursor: 'pointer' }}
        >
          <Typography style={{ paddingRight: 16 }} color="primary">
            1323
          </Typography>
          <Typography color="primary">สายด่วนสุขภาพจิต</Typography>
        </Box>
      </Box>
    </>
  );
};

export const HotMenuTeenage = ({ image, title, url, onClick }) => {
  const [hover, setHover] = useState(false);
  const { item } = useStyles({ hover });
  return (
    <Paper
      className={item}
      onClick={() => onClick(url)}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <img src={`${image}`} alt={title} />
      <Typography>{title}</Typography>
    </Paper>
  );
};

const LoveCare = ({
  color,
  title,
  image,
  description,
  buttonImage,
  url,
  onClick,
}) => {
  const [hover, setHover] = useState(false);
  const { colorBox, colorBoxBtn } = useStyles({
    color,
    hover,
  });
  return (
    <Paper className={colorBox}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        px={0}
        py={1}
      >
        <img src={`${image}`} alt="" />
        <Typography dangerouslySetInnerHTML={{ __html: description }} />
        <Typography variant="h5">{title}</Typography>
        <img
          className={colorBoxBtn}
          src={`${buttonImage}`}
          alt=""
          onClick={() => onClick(url)}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        />
      </Box>
    </Paper>
  );
};

const GuanTeen = ({
  color,
  title,
  title2,
  image,
  description,
  description2,
  buttonImage,
  url,
  onClick,
}) => {
  const [hover, setHover] = useState(false);
  const { colorBox, colorBoxBtn } = useStyles({
    color,
    hover,
  });
  return (
    <Paper className={colorBox}>
      <Grid container style={{ width: '100%' }}>
        <Grid item xs={12} sm={6} style={{ textAlign: 'center' }}>
          <img src={`${image}`} alt="" />
          <Typography dangerouslySetInnerHTML={{ __html: description }} />
          <Typography variant="h5">{title}</Typography>
          <img
            className={colorBoxBtn}
            src={`${buttonImage}`}
            alt=""
            onClick={() => onClick(url)}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box
            height="100%"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            px={2}
          >
            <Typography variant="h5">{title2}</Typography>
            <Typography dangerouslySetInnerHTML={{ __html: description2 }} />
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default HotMenusTeenage;
