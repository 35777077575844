export const items = [
  {
    title: 'ปรึกษากลัวท้อง/ท้องไม่พร้อม',
    image: 'images/home-teenage/HotMenu-01.png',
    url: '/service',
  },
  {
    title: 'ถุงยางฟรีมีที่ไหนบ้าง',
    image: 'images/home-teenage/HotMenu-02.png',
    url: '/condom',
  },
  {
    title: 'กลัวติดโรค หาที่ปรึกษา',
    image: 'images/home-teenage/HotMenu-03.png',
    url: '/service',
  },
  {
    title: 'สถานที่รับ PrEP/PEP',
    image: 'images/home-teenage/HotMenu-04.png',
    url: '/preppep',
  },
];

export const chatItems = [
  {
    color: '#2FC4C1',
    title: 'ห้องแชทเลิฟแคร์',
    image: 'images/home-teenage/love-care-header.png',
    description: `บริการให้การปรึกษาออนไลน์`,
    buttonImage: 'images/home-teenage/love-care-btn.png',
    url: 'http://service.lovecarestation.com/2016/member-login.asp',
  },
  {
    color: '#FF5BA5',
    title: 'ห้องแชทแห่งความลับ',
    image: 'images/home-teenage/hi-teen-gang-header.png',
    description: 'ไขปัญหาคาใจ ความรัก เพศ เรียน และอื่นๆ',
    title2: '"ห้องแห่งความลับ"',
    description2: 'ห้องปรึกษาลับ ๆ สำหรับวัยรุ่น ความรัก เพศ เรียน หรืออื่น ๆ ที่นี่มีคำตอบ',
    buttonImage: 'images/home-teenage/hi-teen-gang-btn.png',
    url: 'https://www.guan-teen.com/secret_room',
  },
];
