import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  content: {
    padding: '16px',
    backgroundColor: 'white',
    width: '100%',
    height: '100%'
  },
  featuredContent: {
    padding: '32px',
    backgroundColor: 'white',
    width: '100%',
    height: '100%'
  },
  image: {
    backgroundImage: ({ image }) => `url("${image}")`,
    backgroundSize: 'cover',
    height: '100%',
    backgroundPosition: 'center'
  },
  header: {
    fontWeight: 'bold',
    marginBottom: '16px'
  },
  headerHilight: {
    borderStyle: 'solid',
    borderWidth: '0 0 2px',
    padding: '2px 0px'
  },
  post: {
    marginBottom: theme.spacing(1)
  },
  postImage: {
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '100%'
  },
  root: {
    minHeight: '426px'
  },
  section: theme.section,
  title: {
    marginBottom: '16px'
  }
}));

const PostGroup = ({ items, onItemClick }) => {
  const { section } = useStyles();
  return (
    <Box p={0.5}>
      <Grid className={section} container spacing={1}>
        <Grid item xs={12} md={8}>
          <Hilight item={items[0]} onClick={onItemClick} />
        </Grid>
        <Grid item xs={12} md={4}>
          <Post item={items[1]} onClick={onItemClick} />
          <Post item={items[2]} onClick={onItemClick} />
          <Post item={items[3]} onClick={onItemClick} />
        </Grid>
      </Grid>
    </Box>
  );
};

export const Hilight = ({ item, onClick }) => {
  const { excerpt, image, title } = item;
  const {
    root,
    featuredContent: contentStyle,
    header,
    headerHilight,
    postImage,
    title: titleStyle
  } = useStyles();

  return (
    <Grid className={root} container>
      <Grid item xs={6}>
        <div
          className={postImage}
          style={{
            backgroundImage: `url("${image}")`
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <div className={contentStyle}>
          <Typography className={header} color="primary">
            <span className={headerHilight}>ข่าว</span>
            ประชาสัมพันธ์
          </Typography>
          <Typography className={titleStyle} variant="h4">
            {title}
          </Typography>
          <Typography variant="subtitle1" paragraph>
            {excerpt}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => onClick(item)}
          >
            อ่านเพิ่มเติม
          </Button>
        </div>
      </Grid>
    </Grid>
  );
};

Hilight.propTypes = {
  item: PropTypes.object.isRequired
};

export const Post = ({ item, onClick }) => {
  const { excerpt, image, title } = item;
  const { content: contentStyle, image: imageStyle, post } = useStyles({
    image
  });
  return (
    <Grid container className={post}>
      <Grid item xs={4}>
        <div className={imageStyle} />
      </Grid>
      <Grid item xs={8}>
        <div className={contentStyle}>
          <Typography style={{ fontWeight: 'bold' }}>{title}</Typography>
          <Typography paragraph style={{ fontSize: '0.8rem' }}>
            {excerpt}
          </Typography>
          <Button color="primary" size="small" onClick={() => onClick(item)}>
            อ่านเพิ่มเติม
          </Button>
        </div>
      </Grid>
    </Grid>
  );
};

Post.propTypes = {
  item: PropTypes.object.isRequired
};

export default PostGroup;
