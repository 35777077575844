import { createMuiTheme } from '@material-ui/core';
import { responsiveFontSizes } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';

const muiTheme = createMuiTheme({
  typography: {
    fontFamily: 'Kanit, Roboto',
    h4: { color: '#1A2F5A' }
  },
  palette: {
    primary: {
      main: red[500]
    }
  }
});

const theme = {
  ...muiTheme,
  layout: {
    [muiTheme.breakpoints.down('md')]: {
      padding: `0px ${muiTheme.spacing(2)}px`
    },
    [muiTheme.breakpoints.up('lg')]: {
      padding: `0px calc((100% - ${muiTheme.breakpoints.values.lg}px) / 2)`
    }
  },
  grow: {
    flexGrow: 1
  },
  responsiveImage: {
    display: 'flex',
    width: '100%'
  },
  section: {
    paddingBottom: '16px'
  },
  truncatedText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }
};

export default responsiveFontSizes(theme);
