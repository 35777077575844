import React from 'react';
import { Box, Button, Paper, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/styles';
import { Save } from '@material-ui/icons';

const useStyles = makeStyles({
  button: {
    margin: '8px 0px',
  },
});

const Share = ({ url, onReset }) => {
  const { button } = useStyles();
  const theme = useTheme();
  return (
    <Paper>
      <Box p={2} bgcolor={theme.palette.primary.main} color="white">
        <Typography variant="h6">รูป hashtag ของคุณพร้อมแล้ว</Typography>
      </Box>
      <Box p={1}>
        <Button
          className={button}
          variant="contained"
          color="primary"
          fullWidth
          startIcon={<Save />}
          onClick={() => window.open(url)}
        >
          ดาวน์โหลด
        </Button>
        <Button
          className={button}
          variant="outlined"
          fullWidth
          onClick={onReset}
        >
          สร้างรูปใหม่
        </Button>
      </Box>
    </Paper>
  );
};

export default Share;
