import React from 'react';
import { Box, Container, Grid, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  banner: {
    width: '100%',
  },
  item: {
    width: '100%',
  },
}));

const GameCenter: React.FC = () => {
  const { banner, item } = useStyles();
  return (
    <Container>
      <img className={banner} src="images/gamecenter/game-center.png" alt="" />
      <Box height={8} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <a href="https://www.bkkhive.com/games/campaign2/" target="_blank">
            <img className={item} src="images/gamecenter/game-1.png" alt="" />
          </a>
        </Grid>
        <Grid item xs={12} sm={4}>
          <a href="https://www.bkkhive.com/games/campaign3/" target="_blank">
            <img className={item} src="images/gamecenter/game-2.png" alt="" />
          </a>
        </Grid>
        <Grid item xs={12} sm={4}>
          <a href="https://www.bkkhive.com/games/campaign4/" target="_blank">
            <img className={item} src="images/gamecenter/game-3.png" alt="" />
          </a>
        </Grid>
        <Grid item xs={12} sm={4}>
          <a href="https://www.bkkhive.com/games/campaign5/" target="_blank">
            <img className={item} src="images/gamecenter/game-4.png" alt="" />
          </a>
        </Grid>
        <Grid item xs={12} sm={4}>
          <a href="https://www.bkkhive.com/games/campaign6/" target="_blank">
            <img className={item} src="images/gamecenter/game-5.png" alt="" />
          </a>
        </Grid>
      </Grid>
      <Box height={8} />
    </Container>
  );
};

export default GameCenter;
