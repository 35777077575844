import React from 'react';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
  dark: {
    backgroundColor: '#F6F7FB'
  },
  layout: theme.layout,
  root: {
    backgroundColor: ({ backgroundColor }) => backgroundColor,
    backgroundImage: ({ backgroundImage }) => backgroundImage && `url('${backgroundImage}')`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    marginTop: ({ verticalMargin }) => verticalMargin,
    marginBottom: ({ verticalMargin }) => verticalMargin,
    paddingTop: ({ verticalPadding }) => verticalPadding,
    paddingBottom: ({ verticalPadding }) => verticalPadding
  },
  section: theme.section
}));

const Container = ({
  backgroundColor,
  backgroundImage,
  children,
  dark,
  fullWidth,
  section,
  verticalMargin,
  verticalPadding,
  ...props
}) => {
  const { dark: darkStyle, layout, root, section: sectionStyle } = useStyles({
    backgroundColor,
    backgroundImage,
    verticalMargin,
    verticalPadding
  });

  return (
    <div
      className={classNames(
        root,
        { [darkStyle]: dark },
        { [layout]: !fullWidth },
        { [sectionStyle]: section }
      )}
      {...props}
    >
      {children}
    </div>
  );
};

export default Container;
