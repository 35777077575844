import React from 'react';
import { Icon as MuiIcon } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  icon: {
    height: '100%',
    marginRight: '8px'
  }
});

const Icon = ({ icon }) => {
  const { icon: iconStyle } = useStyles();
  if (icon.startsWith('http')) {
    return (
      <div className={iconStyle}>
        <img src={icon} alt="" />
      </div>
    );
  }
  return (
    <div className={iconStyle}>
      <MuiIcon color="primary">{icon}</MuiIcon>
    </div>
  );
};

export default Icon;
