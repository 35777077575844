import React from 'react';
import { Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Paginator from './Paginator';

const useStyle = makeStyles(theme => ({
  article: {
    display: 'flex',
    borderRadius: '8px'
  },
  articleExcerpt: {},
  articleInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '8px 16px'
  },
  articleReadMore: {
    color: theme.palette.primary.main,
    cursor: 'pointer'
  },
  articleThumbnail: {
    backgroundImage: ({ image }) => `url('${image}')`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    borderRadius: '8px 0px 0px 8px',
    minHeight: '125px',
    minWidth: '100px'
  },
  articleTitle: {}
}));

const PostList = ({
  items,
  currentPage,
  itemPerPage,
  onArticleClick,
  onPrevPage,
  onNextPage
}) => {
  return (
    <div>
      <Grid container spacing={2}>
        {items
          .slice(currentPage * itemPerPage, (currentPage + 1) * itemPerPage)
          .map((item, index) => (
            <Grid key={index} item xs={12} md={6} lg={4}>
              <PostItem item={item} onClick={onArticleClick} />
            </Grid>
          ))}
      </Grid>
      {items.length > itemPerPage ? ( // paginator required only if total item doesn't fit in one page
        <Paginator
          currentPage={currentPage}
          itemPerPage={itemPerPage}
          totalItem={items.length}
          onPrevPage={onPrevPage}
          onNextPage={onNextPage}
        />
      ) : (
        <div style={{ height: '32px' }} />
      )}
    </div>
  );
};

const PostItem = ({ item, onClick }) => {
  const {
    article,
    articleExcerpt,
    articleInfo,
    articleReadMore,
    articleThumbnail,
    articleTitle
  } = useStyle({
    image: item.image
  });
  return (
    <Paper className={article}>
      <div className={articleThumbnail} />
      <div className={articleInfo}>
        <div>
          <div className={articleTitle}>{item.title}</div>
          <div className={articleExcerpt}>
            <Typography variant="caption">{item.excerpt}</Typography>
          </div>
        </div>
        <div className={articleReadMore} onClick={() => onClick(item)}>
          อ่านเพิ่มเติม
        </div>
      </div>
    </Paper>
  );
};

export default PostList;
