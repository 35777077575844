import React, { useEffect, useState, useCallback } from 'react';
import { Helmet } from 'react-helmet';

import EmptyLoading from '../components/EmptyLoading';
import SinglePost from '../components/SinglePost';

import CloudService from 'common/services/cloud';

const Post = ({ history }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const fetch = useCallback(async () => {
    setLoading(true);
    const postId = history.location.pathname.substring(1).split('/')[1];
    const res = await CloudService.fetch('posts', postId);
    setData(res);
    setLoading(false);
  }, [history.location.pathname]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  if (loading) {
    return <EmptyLoading />;
  }

  return (
    <>
      <Helmet>
        <meta property="og:type" content="website" />
        <meta property="og:title" content="BKK Hive" />
        <meta property="og:description" content="ศูนย์รวมเรื่องเอชไอวี เอดส์ และโรคติดต่อทางเพศสัมพันธ์" />
        {data.image && <meta property="og:image" content={data.image} />}
      </Helmet>
      <SinglePost data={data} />
    </>
  );
};

export default Post;
