import React from 'react';
import {
  Box,
  Button,
  Container,
  Icon,
  Paper,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

const useStyles = makeStyles({
  facebookButton: {
    backgroundColor: '#3b5998',
  },
});

const Campaign1Terms: React.FC = () => {
  const { facebookButton } = useStyles();
  return (
    <Container>
      <Box component={Paper} my={4}>
        <Box px={4} py={4} my={2}>
          <Typography variant="h5">
            สวัสดี มาพบกับกิจกรรม เกมอักษรไขว้พาเพลิน Week ที่ 3 เพียงแค่เล่นเกม ก็ได้ลุ้นรับของรางวัลกันไปเลย
          </Typography>
          <Box mb={1} />
          <Typography style={{ fontWeight: 'bold' }}>
            กติกาง่ายๆ เพียง
          </Typography>
          <Box mb={1} />
          <Typography>
            1. กด Like เพจ Aids station
            <br />
            2. กด Like &amp; Share โพสนี้เป็นสาธารณะ พร้อม #BKKAIDSStation
            <br />
            3. เล่นเกมอักษรไขว้พาเพลิน และ capture หน้าจอสุดท้าย โพสในคอมเมนต์
            เล่นเกมได้
            <br />
            <a
              href="https://www.bkkhive.com/games/campaign7/"
              target="_blank"
              rel="noopener noreferrer"
            >
              ที่นี่
            </a>
          </Typography>
          <Box mb={2} />
          <Typography variant="h5">เงื่อนไข</Typography>
          <Box mb={1} />
          <Typography>
            - แจกรางวัลโดยสุ่มผู้โชคดีจำนวน 5 ท่าน
            <br />
            - ประกาศรายชื่อผู้ได้รับรางวัลวันที่ 30 กันยายน 2563
            <br />
            - การขอและรับรางวัลใดๆ จะต้องกระทำโดยเจ้าของบัญชีเท่านั้น
            <br />
            - เมื่อประกาศรางวัลแล้ว ผู้ได้รับรางวัลติดต่อที่ Inbox ของเพจ
            เพื่อแจ้งชื่อที่อยู่ และเบอร์โทรศัพท์ สำหรับการส่งของรางวัล
            <br />
            - ของรางวัลจะถูกจัดส่งทางไปรษณีย์ ภายใน 30
            วันหลังจากได้รับข้อมูลที่อยู่ของผู้โชคดีครบสมบูรณ์
            <br />
            - ผู้โชคดีจะต้องติดต่อเพื่อรับรางวัล ภายใน 7 วัน
            นับจากวันที่ประกาศรายชื่อผู้โชคดี
            หากไม่ติดต่อกลับตามกำหนดจะถือว่าสละสิทธิ์
            <br />
            - การตัดสินของทางเพจฯ ถือเป็นที่สิ้นสุด
            <br />
            - ทางเพจฯ ขอสงวนสิทธิ์ในการเปลี่ยนแปลงข้อกำหนดเงื่อนไข กติกา
            ระยะเวลาและของรางวัลที่มีมูลค่าเท่ากันทดแทนได้
            โดยไม่จำเป็นต้องแจ้งให้ทราบล่วงหน้า
            <br />- ผู้โชคดีไม่สามารถคืน แลก
            หรือเปลี่ยนรางวัลตามที่กำหนดไว้เป็นเงินสดหรือรางวัลอื่นๆ ได้
            และผู้โชคดีไม่สามารถโอนสิทธิ์หรือขายสิทธิ์ให้ผู้อื่นได้
          </Typography>
          <Box mb={2} />
          <Typography variant="h5">ผู้มีสิทธิ์ร่วมลุ้นรับรางวัล</Typography>
          <Box mb={1} />
          <Typography>
            - ผู้ที่มีสัญชาติไทยเท่านั้น
            <br />- ผู้ที่พำนักอาศัยในประเทศไทยเท่านั้น
          </Typography>
          <Box display="flex" justifyContent="center" mt={2}>
            <Button
              variant="contained"
              color="secondary"
              classes={{ root: facebookButton }}
              size="large"
              onClick={() =>
                window.open(
                  'https://www.facebook.com/AIDSStation/posts/2870729386362229',
                  '_blank'
                )
              }
            >
              <Icon className={clsx('fab fa-facebook-f')} /> ดูโพสต์กิจกรรม
            </Button>
            <Box mr={2} />
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => window.open('/games/campaign7')}
            >
              เข้าสู่กิจกรรม
            </Button>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default Campaign1Terms;
