import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core';
import { makeStyles, ThemeProvider } from '@material-ui/styles';

const genders = [
  { text: 'ชาย', val: 'male' },
  { text: 'หญิง', val: 'female' },
  { text: 'ชายรักชาย', val: 'msm' },
  { text: 'คนข้ามเพศ', val: 'tg' },
  { text: 'หลากหลายทางเพศ', val: 'lgbtq' },
];
const ages = [
  { text: 'น้อยกว่า 15 ปี', val: 'lessthan15' },
  { text: '15-30 ปี', val: '15-30' },
  { text: '31- 40 ปี', val: '31-40' },
  { text: '41-50 ปี', val: '41-50' },
  { text: '51 ปี ขึ้นไป', val: 'morethan51' },
];
const knowFroms = [
  { text: 'คนรู้จัก/เพื่อนแนะนำ', val: 'friend' },
  {
    text:
      'ตามลิงค์มาจากแคมเปญกิจกรรมที่หน้า facebook เพจ AIDS Station หรือเพจอื่น ๆ',
    val: 'campaign',
  },
  { text: 'ค้นหาจาก Google หรือ search engine อื่นๆ', val: 'searchEngine' },
];

const useStyles = makeStyles({
  formControl: {
    marginBottom: 16,
  },
  formLabel: {
    marginBottom: 8,
  },
  radioRoot: {
    marginRight: 7,
    padding: 2,
  },
});

const SurveyFormDialog = ({ open, onSubmit, onSetOpen }) => {
  const [form, setForm] = useState({
    age: '',
    gender: '',
    knowfrom: '',
    knowfromText: '',
    other: '',
    score: '',
  });
  const enabledSubmit = () =>
    form.age !== '' &&
    form.gender !== '' &&
    form.knowfrom !== '' &&
    form.score !== '';
  const handleClose = () => {
    onSetOpen(false);
  };
  const handleInputChange = (id) => (e) => {
    setForm({ ...form, [id]: e.target.value });
  };
  const handleSubmit = (e) => {
    console.log('submit');
    onSubmit(form);
  };
  const { dialog, formControl, formLabel, radioRoot } = useStyles();
  const createTheme = (mainTheme) => ({
    ...mainTheme,
    typography: { ...mainTheme.typography, body1: { fontSize: '0.8rem' } },
  });

  return (
    <ThemeProvider theme={createTheme}>
      <Dialog
        className={dialog}
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">แบบประเมินความพึงพอใจ</DialogTitle>
        <DialogContent>
          <FormGroup>
            <FormControl className={formControl}>
              <FormLabel className={formLabel}>1. เพศของคุณ</FormLabel>
              <RadioGroup
                aria-label="gender"
                name="gender"
                value={form.gender}
                onChange={handleInputChange('gender')}
              >
                {genders.map((gender) => (
                  <FormControlLabel
                    key={gender.val}
                    value={gender.val}
                    control={
                      <Radio
                        classes={{ root: radioRoot }}
                        size="small"
                        color="primary"
                      />
                    }
                    label={gender.text}
                  />
                ))}
              </RadioGroup>
            </FormControl>
            <FormControl className={formControl}>
              <FormLabel className={formLabel}>2. ช่วงอายุ</FormLabel>
              <RadioGroup
                aria-label="age"
                name="age"
                value={form.age}
                onChange={handleInputChange('age')}
              >
                {ages.map((data) => (
                  <FormControlLabel
                    key={data.val}
                    value={data.val}
                    control={
                      <Radio
                        classes={{ root: radioRoot }}
                        size="small"
                        color="primary"
                      />
                    }
                    label={data.text}
                  />
                ))}
              </RadioGroup>
            </FormControl>
            <FormControl className={formControl}>
              <FormLabel className={formLabel}>
                3. คุณรู้จักเว็บไซต์ bkkhive จากที่ใด
              </FormLabel>
              <RadioGroup
                aria-label="knowfrom"
                name="knowfrom"
                value={form.knowfrom}
                onChange={handleInputChange('knowfrom')}
              >
                {knowFroms.map((data) => (
                  <FormControlLabel
                    key={data.val}
                    value={data.val}
                    control={
                      <Radio
                        classes={{ root: radioRoot }}
                        size="small"
                        color="primary"
                      />
                    }
                    label={data.text}
                  />
                ))}
                <Box display="flex" alignItems="center">
                  <FormControlLabel
                    value="other"
                    control={
                      <Radio
                        classes={{ root: radioRoot }}
                        size="small"
                        color="primary"
                      />
                    }
                    label="อื่นๆ"
                  />
                  <TextField
                    id="knowfromText"
                    placeholder="ระบุ"
                    size="small"
                    value={form.knowfromText}
                    onChange={handleInputChange('knowfromText')}
                    disabled={form.knowfrom !== 'other'}
                  />
                </Box>
              </RadioGroup>
            </FormControl>
            <FormControl className={formControl}>
              <FormLabel className={formLabel}>
                4. คุณคิดว่าเว็บไซต์มีประโยชน์มากน้อยเพียงใด
              </FormLabel>
              <RadioGroup
                aria-label="score"
                name="score"
                value={form.score}
                onChange={handleInputChange('score')}
              >
                {['5', '4', '3', '2', '1'].map((val) => (
                  <FormControlLabel
                    key={val}
                    value={val}
                    control={
                      <Radio
                        classes={{ root: radioRoot }}
                        size="small"
                        color="primary"
                      />
                    }
                    label={val}
                  />
                ))}
              </RadioGroup>
            </FormControl>
            <FormControl className={formControl}>
              <TextField
                id="other"
                label="ข้อเสนอแนะอื่นๆ เพิ่มเติม"
                variant="outlined"
                value={form.other}
                onChange={handleInputChange('other')}
                multiline
                rows={4}
              />
            </FormControl>
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            ปิด
          </Button>
          <Button
            color="primary"
            variant="contained"
            disabled={!enabledSubmit()}
            onClick={handleSubmit}
          >
            ส่งข้อมูล
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default SurveyFormDialog;
