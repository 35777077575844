import React from 'react';
import { Grid, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { OpenInNew } from '@material-ui/icons';
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
  contact: {
    backgroundImage: ({ image }) => `url('${image}')`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    borderRadius: '8px',
    height: '300px',
    position: 'relative'
  },
  contactInfo: {
    backgroundColor: 'white',
    borderRadius: '0px 0px 8px 8px',
    bottom: '0px',
    display: 'flex',
    minHeight: '64px',
    padding: '16px',
    position: 'absolute',
    left: '0px',
    right: '0px'
  },
  contactTitle: {
    alignItems: 'center',
    display: 'flex',
    flexGrow: '1'
  },
  gridContainer: {
    padding: theme.spacing(2)
  },
  root: {
    padding: `${theme.spacing(2)}px 0px`
  },
  section: theme.section
}));

const OnlineAppointment = ({
  title,
  description,
  items,
  onItemClick
}) => {
  const { gridContainer, root, section } = useStyles();

  return (
    <div className={root}>
      <Typography variant="h4" align="center" style={{ marginBottom: '16px' }}>
        {title}
      </Typography>
      <div>{description}</div>
      <Grid
        container
        className={classNames(gridContainer, section)}
        spacing={2}
      >
        {items.map((item, index) => (
          <Grid key={index} item xs={12} sm={4}>
            <ContactItem item={item} onClick={onItemClick} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

const ContactItem = ({ item, onClick }) => {
  const { contact, contactInfo, contactTitle } = useStyles({
    image: item.image
  });
  return (
    <div className={contact}>
      <div className={contactInfo}>
        <div className={contactTitle}>{item.title}</div>
        <IconButton onClick={() => onClick(item.url)}>
          <OpenInNew color="secondary" />
        </IconButton>
      </div>
    </div>
  );
};

export default OnlineAppointment;
