export const items = [
  {
    title: 'สถานที่รับ PrEP/PEP',
    image: 'images/home-msm/hotmenu-1.png',
    url: '/preppep'
  },
  {
    title: 'ถุงยางฟรีมีที่ไหนบ้าง',
    image: 'images/home-msm/hotmenu-2.png',
    url: '/condom'
  },
  {
    title: 'กลัวติดโรค หาที่ปรึกษา',
    image: 'images/home-msm/hotmenu-3.png',
    url: '/service'
  },
  {
    title: 'นัดตรวจเลือดออนไลน์',
    image: 'images/home-msm/hotmenu-4.png',
    url: '/service'
  }
];
